import { runningOnBrowser } from '../../../../utils/helpers/helpers'
import DarkTheme from '../../Dark/DarkTheme'
import LightTheme from '../../Light/LightTheme'
import Theme from '../../Theme'
import ThemeAPI from '../ThemeAPI'

class LocalStorageThemeAPI implements ThemeAPI {
  static keyName = 'theme'

  save(theme: Theme) {
    if (runningOnBrowser()) {
      const themeSaveObject: ThemeSaveObject = {
        id: theme.id,
        primaryColorPalletName: theme.primaryColorPallet.name,
      }
      window.localStorage.setItem(
        LocalStorageThemeAPI.keyName,
        JSON.stringify(themeSaveObject)
      )
    }
  }
  load() {
    if (runningOnBrowser()) {
      try {
        const themeSaveObjectString = window.localStorage.getItem(
          LocalStorageThemeAPI.keyName
        )
        if (themeSaveObjectString) {
          const themeSaveObject = JSON.parse(
            themeSaveObjectString
          ) as ThemeSaveObject
          const theme = {
            [DarkTheme.id]: new DarkTheme(),
            [LightTheme.id]: new LightTheme(),
          }[themeSaveObject.id]
          return theme || null
        } else return null
      } catch (e) {
        return null
      }
    }
    return null
  }
}

interface ThemeSaveObject {
  id: string
  primaryColorPalletName: string
}

export default LocalStorageThemeAPI
