import colorPallets from '../ColorPallets'
import Theme from '../Theme'

class LightTheme extends Theme {
  static id = 'light'

  constructor() {
    const primaryColorPallet = colorPallets.purple
    super({
      id: LightTheme.id,
      primaryColorPallet,
      text: {
        colors: {
          base: colorPallets.gray[800],
          bold: '#000000',
          accent: colorPallets.gray[500],
          primary: {
            base: primaryColorPallet[500],
            bold: primaryColorPallet[700],
            accent: primaryColorPallet[300],
          },
          highlight: colorPallets.yellow[300],
          link: primaryColorPallet[500],
          error: colorPallets.red[500],
          warning: colorPallets.orange[500],
          success: colorPallets.green[500],
          light: colorPallets.gray[100],
          dark: colorPallets.gray[800],
        },
      },
      background: {
        colors: {
          base: colorPallets.gray[100],
          bold: '#EEF2FF',
          accent: colorPallets.gray[300],
          primary: {
            base: primaryColorPallet[500],
            bold: primaryColorPallet[700],
            accent: '#EEF2FF',
          },
          error: colorPallets.red[300],
          warning: colorPallets.orange[300],
          success: colorPallets.green[300],
          light: colorPallets.gray[100],
          dark: colorPallets.gray[900],
        },
      },
    })
  }
}

export default LightTheme
