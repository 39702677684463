import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber, uid } from '../../../../frontend/utils/helpers/helpers'

class Npc {
  id: string
  name: string
  description: string
  avatarImage: string
  fullBodyImage: string
  emailAddress: string
  constructor(
    id: string,
    name: string,
    description: string,
    avatarImage: string,
    fullBodyImage: string,
    emailAddress: string
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.avatarImage = avatarImage
    this.fullBodyImage = fullBodyImage
    this.emailAddress = emailAddress

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      avatarImage: observable,
      fullBodyImage: observable,
      emailAddress: observable,
    })
  }

  static withFakeData = () => {
    const lorem = new LoremIpsum()

    return new Npc(
      uid(),
      lorem.generateWords(2),
      lorem.generateSentences(randomNumber(1, 3)),
      'https://drive.google.com/uc?export=download&id=1Z6YzO8syZ1qH2O_l7fTBtn5BAN88pG8a',
      'https://drive.google.com/uc?export=download&id=11QEi4do6pcb3-dRNUaWzmgVEJCP7K4TE',
      `${lorem.generateWords(1)}@simulator.dev`
    )
  }
}

export const npcs: Npc[] = []

export default Npc
