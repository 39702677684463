import { computed, makeObservable, observable } from 'mobx'
import UIField from '../UIField/UIField'

class UIFieldCollection {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: UIField<any>[]
  isValidating = false

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(fields: UIField<any>[]) {
    this.fields = fields

    makeObservable(this, {
      fields: observable,
      isValidating: observable,
      isValid: computed,
    })
  }

  get isValid() {
    let isValid = true
    this.fields.forEach((field) => {
      if (!field.isValid) isValid = false
    })
    return isValid
  }

  get hasErrors() {
    let hasErrors = false
    this.fields.forEach((field) => {
      if (field.hasErrors) hasErrors = true
    })
    return hasErrors
  }

  async validate() {
    this.isValidating = true
    try {
      await Promise.all(this.fields.map((f) => f.validate()))
    } finally {
      this.isValidating = false
    }
  }
}

export default UIFieldCollection
