import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../utils/types'
import { ClipLoader } from 'react-spinners'
import { observer } from 'mobx-react-lite'
import useApp from '../../AppContextProvider/hooks/useApp'

const Spinner = ({ className, size, color, ...rest }: SpinnerProps) => {
  const app = useApp()
  return (
    <div
      className={`spinner flex items-center justify-center ${className}`}
      {...rest}
    >
      <ClipLoader size={size} color={color || app.theme.text.colors.base} />
    </div>
  )
}

export interface SpinnerProps extends HtmlDiv {
  size: string
  color?: string
}

export default styled(observer(Spinner))``
