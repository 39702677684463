import firebase from 'firebase'
import UIError from '../../../UIError/UIError'
import User from '../../User'
import UserAPI from '../UserApi'
import type stripe from '@stripe/stripe-js'
import Firebase from '../../../Firebase/Firebase'
import Avatar from '../../../Avatar/Avatar'
import Game from '../../../Game/Game'

class FirebaseUserAPI implements UserAPI {
  firebase: Firebase
  getStripe: () => Promise<stripe.Stripe>
  stripe: stripe.Stripe | null = null

  constructor(firebase: Firebase, getStripe: () => Promise<stripe.Stripe>) {
    this.firebase = firebase
    this.getStripe = getStripe
  }

  async signUp(email: string, password: string): Promise<User> {
    try {
      const {
        user: firebaseUser,
      } = await this.firebase.auth.createUserWithEmailAndPassword(
        email,
        password
      )
      if (firebaseUser && firebaseUser.email) {
        return new User(
          firebaseUser.uid,
          firebaseUser.email,
          firebaseUser.emailVerified,
          false,
          {} as Avatar,
          {} as Game,
          this
        )
      } else
        throw new UIError(
          'FirebaseUserAPI_signup',
          'An unexpected error occurred, unable to sign up'
        )
    } catch (e) {
      throw e.isInternalError
        ? e
        : new UIError(
            'FirebaseUserAPI_signup',
            getFirebaseErrorMessage(e.code),
            e
          )
    }
  }

  async signIn(email: string, password: string): Promise<User> {
    try {
      const {
        user: firebaseUser,
      } = await this.firebase.auth.signInWithEmailAndPassword(email, password)

      if (firebaseUser && firebaseUser.email) {
        const { claims: firebaseClaims } = await firebaseUser.getIdTokenResult(
          true
        )
        return new User(
          firebaseUser.uid,
          firebaseUser.email,
          firebaseUser.emailVerified,
          firebaseClaims.stripeRole === 'bootcamper',
          {} as Avatar,
          {} as Game,
          this
        )
      } else
        throw new UIError(
          'FirebaseUserAPI_signIn',
          'An unexpected error occurred'
        )
    } catch (e) {
      throw e.isInternalError
        ? e
        : new UIError(
            'FirebaseUserAPI_signIn',
            'Email and password do not match',
            e
          )
    }
  }

  async signOut(): Promise<void> {
    try {
      return await this.firebase.auth.signOut()
    } catch (e) {
      throw new UIError('FirebaseUserAPI_signOut', 'Unable to sign out', e)
    }
  }

  async updateEmail(
    newEmail: string,
    currentEmail: string,
    password: string
  ): Promise<void> {
    if (this.firebase.auth.currentUser) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentEmail,
        password
      )

      try {
        await this.firebase.auth.currentUser.reauthenticateWithCredential(
          credential
        )
      } catch (e) {
        throw new UIError(
          'FirebaseUserAPI_updateEmail',
          'Email and password do not match',
          e
        )
      }

      try {
        await this.firebase.auth.currentUser.updateEmail(newEmail)
      } catch (e) {
        throw new UIError(
          'FirebaseUserAPI_updateEmail',
          getFirebaseErrorMessage(e.code),
          e
        )
      }
    } else
      throw new UIError('FirebaseUserAPI_updateEmail', 'No user is signed in')
  }

  async updatePassword(
    currentPassword: string,
    newPassword: string,
    email: string
  ): Promise<void> {
    if (this.firebase.auth.currentUser) {
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          email,
          currentPassword
        )

        await this.firebase.auth.currentUser.reauthenticateWithCredential(
          credential
        )
      } catch (e) {
        throw new UIError(
          'FirebaseUserAPI_updatePassword',
          'Incorrect current password',
          e
        )
      }

      try {
        return await this.firebase.auth.currentUser.updatePassword(newPassword)
      } catch (e) {
        throw new UIError(
          'FirebaseUserAPI_updatePassword',
          getFirebaseErrorMessage(e.code),
          e
        )
      }
    } else
      throw new UIError(
        'FirebaseUserAPI_updatePassword',
        'No user is signed in'
      )
  }

  async getInitialUserStatus(): Promise<User | null> {
    try {
      const firebaseUser = await new Promise<firebase.User>((resolve, reject) =>
        this.firebase.auth.onAuthStateChanged(
          (user) => {
            if (user) {
              resolve(user)
            } else {
              reject('no user logged in')
            }
          },
          (error) => reject(error)
        )
      )

      const { claims: firebaseClaims } = await firebaseUser.getIdTokenResult(
        true
      )
      const { uid, email, emailVerified } = firebaseUser
      return new User(
        uid,
        email || '',
        emailVerified,
        firebaseClaims.stripeRole === 'bootcamper',
        {} as Avatar,
        {} as Game,
        this
      )
    } catch (error) {
      return null
    }
  }

  async sendPasswordRecoveryEmail(email: string): Promise<void> {
    try {
      await this.firebase.auth.sendPasswordResetEmail(email)
    } catch (e) {
      //
    }
  }

  async sendVerificationEmail(): Promise<void> {
    if (
      this.firebase.auth.currentUser &&
      this.firebase.auth.currentUser.email
    ) {
      try {
        return await this.firebase.auth.currentUser.sendEmailVerification()
      } catch (e) {
        throw new UIError(
          'FirebaseUserAPI_sendVerificationEmail',
          getFirebaseErrorMessage(e.code),
          e
        )
      }
    } else
      throw new UIError(
        'FirebaseUserAPI_sendVerificationEmail',
        'No user is signed in'
      )
  }

  async redirectToCheckoutPage(
    userId: string,
    priceId: string,
    successUrl: string,
    cancelUrl: string
  ): Promise<void> {
    return new Promise<void>((res, rej) => {
      const redirect = async () => {
        try {
          const docRef = await this.createCheckoutSession(
            userId,
            priceId,
            successUrl,
            cancelUrl
          )
          docRef.onSnapshot(async (snapshot) => {
            if (snapshot && snapshot.data()?.sessionId) {
              await this.redirectToCheckoutPageFromSnapshot(snapshot)
              res()
            }
          })
        } catch (e) {
          rej(
            new UIError(
              'FirebaseUserAPI_redirectToCheckout',
              'Unable to redirect to checkout page, please refresh the page and try again.',
              e
            )
          )
        }
      }
      redirect()
    })
  }

  async redirectToPortalPage(): Promise<void> {
    try {
      const functionRef = this.firebase.functions.httpsCallable(
        'ext-firestore-stripe-subscriptions-createPortalLink'
      )

      const { data } = await functionRef({ returnUrl: window.location.origin })
      return window.location.assign(data.url)
    } catch (e) {
      throw new UIError(
        'FirebaseUserAPI_redirectToPortalPage',
        'Unable to redirect to subscription portal page',
        e
      )
    }
  }

  async redirectToCheckoutPageFromSnapshot(
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) {
    try {
      const data = snapshot.data()
      if (data) {
        const { error, sessionId } = data
        if (error) throw `There was an error on the data snapshot: ${error}`
        if (sessionId) {
          if (!this.stripe) this.stripe = await this.getStripe()
          await this.stripe.redirectToCheckout({ sessionId })
        } else throw 'There was no sessionId on the data snapshot'
      } else throw 'There was no data on the snapshot'
    } catch (e) {
      throw new UIError(
        'FirebaseUserAPI_redirectToCheckoutPageFromSnapshot',
        'Unable to redirect to payment page',
        e
      )
    }
  }

  async createCheckoutSession(
    uid: string,
    price: string,
    successUrl: string,
    cancelUrl: string
  ) {
    try {
      return await this.firebase.firestore
        .collection('customers')
        .doc(uid)
        .collection('checkout_sessions')
        .add({
          price,
          success_url: successUrl,
          cancel_url: cancelUrl,
        })
    } catch (e) {
      throw new UIError(
        'FirebaseUserAPI_createCheckoutSession',
        'Unable to create checkout session',
        e
      )
    }
  }
}

const getFirebaseErrorMessage = (errorCode: string) => {
  return (
    FIREBASE_AUTH_ERROR_CODES[errorCode] ||
    'An unexpected error has occurred, please refresh the page and try again.'
  )
}

export const FIREBASE_AUTH_ERROR_CODES: Record<string, string> = {
  'auth/network-request-failed': 'Unable to connect to internet.',
  'auth/user-disabled': 'The requested account has been disabled.',
  'auth/user-token-expired':
    'Your session has expired, please log out and log back in again.',
  'auth/email-already-in-use':
    'The email address is already in use by another account.',
  'auth/invalid-email': 'Invalid email.',
  'auth/operation-not-allowed':
    'There was a problem contacting our servers, please refresh the page and try again.',
  'auth/weak-password': 'Password should be at least 6 characters.',
  'auth/user-not-found': 'User not found.',
  'auth/wrong-password': 'Email and password do not match.',
  'auth/null-user': 'User not found.',
}

export default FirebaseUserAPI
