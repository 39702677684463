import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber, uid } from '../../../utils/helpers/helpers'
import InMemoryUIModelAPI from '../../UIFieldCollection/UIModel/API/InMemory/InMemoryUIModelAPI'
import UIModelAPI from '../../UIFieldCollection/UIModel/API/UIModelAPI'
import UIModel, {
  UIModelFields,
  UIModelOptions,
} from '../../UIFieldCollection/UIModel/UIModel'
import Company from '../Company/Company'
import Game from '../Game'
import Npc from '../Npc/Npc'
import Skill from '../Skill/Skill'
import Task, { PlayerTask, TaskSaveObject } from '../Task/Task'

interface Contract {
  id: string
  name: string
  writeUp: JSX.Element | string
  tasks: Task[]
  skillRewards: Skill[]
  prerequisiteContractIds: string[]
  prerequisiteSkills: Skill[]
  startCodeGitUrl: string
  endCodeGitUrl: string
  npc: Npc
  company: Company
}

export default Contract

export class PlayerContract extends UIModel implements Contract {
  name: string
  status: PlayerContractStatus
  writeUp: JSX.Element | string
  tasks: PlayerTask[]
  skillRewards: Skill[]
  prerequisiteContractIds: string[]
  prerequisiteSkills: Skill[]
  modifiedFiles: CodeFile[]
  startCodeGitUrl: string
  endCodeGitUrl: string
  npc: Npc
  company: Company

  constructor(
    contract: Contract,
    gameId: string,
    status: PlayerContractStatus,
    tasks: PlayerTask[],
    modifiedFiles: CodeFile[],
    uiModelAPI: UIModelAPI,
    options?: UIModelOptions
  ) {
    super(contract.id, [], `${gameId}/contracts`, uiModelAPI, options)

    this.name = contract.name
    this.status = status
    this.writeUp = contract.writeUp
    this.modifiedFiles = modifiedFiles
    this.tasks = tasks
    this.skillRewards = contract.skillRewards
    this.prerequisiteContractIds = contract.prerequisiteContractIds
    this.prerequisiteSkills = contract.prerequisiteSkills
    this.startCodeGitUrl = contract.startCodeGitUrl
    this.endCodeGitUrl = contract.endCodeGitUrl
    this.npc = contract.npc
    this.company = contract.company

    makeObservable(this, {
      name: observable,
      status: observable,
      writeUp: observable,
      modifiedFiles: observable,
      tasks: observable,
      skillRewards: observable,
      prerequisiteContractIds: observable,
      prerequisiteSkills: observable,
      startCodeGitUrl: observable,
      endCodeGitUrl: observable,
      npc: observable,
      company: observable,
    })
  }

  isUnlocked(playerSkills: Skill[], playerCompletedContractIds: string[]) {
    let hasQualifyingSkills = true
    this.prerequisiteSkills.forEach((skill) => {
      const skillToCheck = playerSkills.find((s) => s.name === skill.name)
      if (!skillToCheck || skillToCheck.xp < skill.xp)
        hasQualifyingSkills = false
    })

    let hasQualifyingCompletedContracts = true
    this.prerequisiteContractIds.forEach((prerequisiteContractId) => {
      if (!playerCompletedContractIds.includes(prerequisiteContractId))
        hasQualifyingCompletedContracts = false
    })

    return hasQualifyingSkills && hasQualifyingCompletedContracts
  }

  static async loadAll(gameId: string, uiModelAPI: UIModelAPI) {
    const savedContracts = (await uiModelAPI.getAll(
      `${Game.collection}/${gameId}/contracts`
    )) as ContractSaveObject[]
    const savedTasks = (await uiModelAPI.getAll(
      `${Game.collection}/${gameId}/tasks`
    )) as TaskSaveObject[]

    const playerContracts = contracts.map((contract) => {
      const savedContract = savedContracts.find((c) => c.id === contract.id)
      const playerTasks = contract.tasks.map((task) => {
        const savedTask = savedTasks.find((t) => t.id === task.id)
        return new PlayerTask(
          task,
          gameId,
          !!savedTask?.isComplete,
          uiModelAPI,
          { isSaved: true }
        )
      })
      return new PlayerContract(
        contract,
        gameId,
        savedContract?.status || PlayerContractStatus.NOT_STARTED,
        playerTasks,
        savedContract?.modifiedFiles || [],
        uiModelAPI,
        { isSaved: true }
      )
    })
    return playerContracts
  }

  toSaveObject() {
    return {
      ...super.toSaveObject(),
      status: this.status,
      modifiedFiles: this.modifiedFiles,
    }
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    const tasks: PlayerTask[] = []
    for (let i = 0; i < randomNumber(3, 10); i++)
      tasks.push(PlayerTask.withFakeData())

    const skills: Skill[] = []
    for (let i = 0; i < randomNumber(1, 3); i++)
      skills.push(Skill.withFakeData())

    return new PlayerContract(
      {
        id: uid(),
        name: lorem.generateWords(randomNumber(1, 3)),
        writeUp: lorem.generateParagraphs(randomNumber(1, 3)),
        tasks: tasks,
        skillRewards: skills,
        prerequisiteContractIds: [],
        prerequisiteSkills: [],
        startCodeGitUrl: lorem.generateWords(1),
        endCodeGitUrl: lorem.generateWords(1),
        npc: Npc.withFakeData(),
        company: Company.withFakeData(),
      },
      uid(),
      PlayerContractStatus.IN_PROGRESS,
      tasks,
      [],
      new InMemoryUIModelAPI()
    )
  }
}

export interface ContractSaveObject extends UIModelFields {
  status: PlayerContractStatus
  modifiedFiles: CodeFile[]
}

export interface CodeFile {
  path: string
  text: string
}

export enum PlayerContractStatus {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETE,
}

export const contracts: Contract[] = []
