import EmailUIField from '../UIField/String/Email/EmailUIField'
import PasswordUIField from '../UIField/String/Password/PasswordUIField'
import UIForm from '../UIFieldCollection/UIForm/UIForm'
import Avatar from '../Avatar/Avatar'
import Game from '../Game/Game'
import UserAPI from './API/UserApi'
import { makeObservable, observable } from 'mobx'

class User {
  id: string
  avatar: Avatar
  game: Game
  email: string
  isEmailVerified: boolean
  isBootcamper: boolean
  userAPI: UserAPI

  constructor(
    id: string,
    email: string,
    isEmailVerified: boolean,
    isBootcamper: boolean,
    avatar: Avatar,
    game: Game,
    userAPI: UserAPI
  ) {
    this.id = id
    this.isEmailVerified = isEmailVerified
    this.isBootcamper = isBootcamper
    this.email = email
    this.userAPI = userAPI
    this.avatar = avatar
    this.game = game

    makeObservable(this, {
      id: observable,
      isEmailVerified: observable,
      isBootcamper: observable,
      email: observable,
      avatar: observable,
      game: observable,
    })
  }

  async updateEmail(newEmail: string, password: string) {
    await this.userAPI.updateEmail(newEmail, this.email, password)
  }
  getUpdateEmailUIForm() {
    const newEmailField = new EmailUIField('New Email', '')
    const passwordField = new PasswordUIField('Password', '')

    return new UIForm([newEmailField, passwordField], async () => {
      await this.updateEmail(newEmailField.value, passwordField.value)
    })
  }

  async updatePassword(newPassword: string, currentPassword: string) {
    await this.userAPI.updatePassword(newPassword, currentPassword, this.email)
  }
  getUpdatePasswordUIForm() {
    const currentPasswordField = new PasswordUIField('Current Password', '')
    const newPasswordField = new PasswordUIField('New Password', '')

    return new UIForm([currentPasswordField, newPasswordField], async () => {
      await this.updatePassword(
        newPasswordField.value,
        currentPasswordField.value
      )
    })
  }

  async sendVerificationEmail() {
    await this.userAPI.sendVerificationEmail(this.email)
  }

  async redirectToCheckoutPage(
    price: string,
    successUrl: string,
    cancelUrl: string
  ) {
    await this.userAPI.redirectToCheckoutPage(
      this.id,
      price,
      successUrl,
      cancelUrl
    )
  }

  async redirectToPortalPage() {
    await this.userAPI.redirectToPortalPage()
  }
}

export default User
