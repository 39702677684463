import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { HtmlDiv } from '../../../utils/types'
import useApp from '../../AppContextProvider/hooks/useApp'
import Auth from '../../Auth/Auth'
import Button from '../../Core/Inputs/Button/Button'
import Link from '../../Core/Navigation/Link/Link'
import ModalTrigger from '../../Core/Surfaces/Modal/Trigger/ModalTrigger'

const SignUpPrompt = ({ className, ...rest }: SignUpPromptProps) => {
  const app = useApp()

  return (
    <div className={`flex justify-center`}>
      {!app.user && (
        <div
          className={`mt-8 flex flex-wrap justify-center items-center bg-accent p-8 rounded-lg ${className}`}
          {...rest}
        >
          <div>
            <div className="text-2xl font-bold">
              Sign up now for early access
            </div>
            <div className="text-md">
              Dev Simulator is launching in a few months, sign up now and...
            </div>
            <ul className="signup-item-list">
              <li>Reserve your avatar name</li>
              <li>Participate in our open beta this summer</li>
              <li>
                Get 25% off the{' '}
                <Link to="#bootcamp" className="underline">
                  Dev Simulator Bootcamp
                </Link>
              </li>
              <li>Vote on core game features</li>
            </ul>
          </div>
          <div className="flex justify-center items-center">
            <ModalTrigger
              name="Welcome to Dev Simulator!"
              Trigger={<Button>Join Now!</Button>}
              onClose={() => {}}
            >
              <Auth className="w-xs text-base" />
            </ModalTrigger>
          </div>
        </div>
      )}
    </div>
  )
}

export interface SignUpPromptProps extends HtmlDiv {}

export default styled(observer(SignUpPrompt))`
  .signup-item-list {
    li {
      ${tw`my-1`}
    }
  }
  .signup-btn {
    box-shadow: 0.25rem 0.25rem 0 ${(props) => props.theme.text.colors.bold};
  }
`
