import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber, uid } from '../../utils/helpers/helpers'
import InMemoryUIModelAPI from '../UIFieldCollection/UIModel/API/InMemory/InMemoryUIModelAPI'
import UIModelAPI from '../UIFieldCollection/UIModel/API/UIModelAPI'
import UIModel, {
  UIModelFields,
  UIModelOptions,
} from '../UIFieldCollection/UIModel/UIModel'
import { PlayerContract, PlayerContractStatus } from './Contract/Contract'
import { PlayerEmailThread } from './Email/Thread/EmailThread'
import JobTitle, { jobTitles } from './JobTitle/JobTitle'
import Skill from './Skill/Skill'
import SkillTitle, { skillTitles } from './Skill/Title/SkillTitle'

class Game extends UIModel {
  contracts: PlayerContract[]
  emailThreads: PlayerEmailThread[]
  activeSkillTitleName: string | null
  skillTitles: SkillTitle[] = skillTitles
  jobTitles: JobTitle[] = jobTitles
  static collection = 'games'

  constructor(
    id: string,
    contracts: PlayerContract[],
    emailThreads: PlayerEmailThread[],
    activeSkillTitleName: string | null,
    uiModelAPI: UIModelAPI,
    options?: UIModelOptions
  ) {
    super(id, [], Game.collection, uiModelAPI, options)

    this.activeSkillTitleName = activeSkillTitleName
    this.contracts = contracts
    this.emailThreads = emailThreads

    makeObservable(this, {
      contracts: observable,
      emailThreads: observable,
      activeSkillTitleName: observable,
    })
  }

  static async load(id: string, uiModelAPI: UIModelAPI) {
    const savedGame = (await uiModelAPI.get(
      id,
      Game.collection
    )) as GameSaveObject

    const playerContracts = await PlayerContract.loadAll(id, uiModelAPI)
    const playerEmailThreads = await PlayerEmailThread.loadAll(id, uiModelAPI)

    if (savedGame) {
      return new Game(
        id,
        playerContracts,
        playerEmailThreads,
        savedGame.activeSkillTitleName,
        uiModelAPI,
        { isSaved: true }
      )
    }
    return new Game(id, playerContracts, playerEmailThreads, null, uiModelAPI)
  }

  get jobTitle() {
    const jobTitlesThePlayerQualifiesFor = this.jobTitles.filter((j) =>
      j.isUnlocked(
        this.contracts
          .filter((c) => c.status === PlayerContractStatus.COMPLETE)
          .map((c) => c.id)
      )
    )
    const highestLevelQualifyingJobTitle =
      jobTitlesThePlayerQualifiesFor[jobTitlesThePlayerQualifiesFor.length - 1]
    return highestLevelQualifyingJobTitle
  }

  getEarnedSkills(): Skill[] {
    const allSkills = {} as Record<string, number>
    this.contracts.forEach((contract) => {
      if (contract.status === PlayerContractStatus.COMPLETE) {
        contract.skillRewards.forEach((skillReward) => {
          if (!allSkills[skillReward.name]) allSkills[skillReward.name] = 0
          allSkills[skillReward.name] += skillReward.xp
        })
      }
    })

    return Object.keys(allSkills).map((skillName) => {
      return {
        id: 'id',
        name: skillName,
        xp: allSkills[skillName],
        description: 'description',
        image: 'image',
      }
    })
  }

  toSaveObject(): GameSaveObject {
    return {
      ...super.toSaveObject(),
      activeSkillTitleName: this.activeSkillTitleName,
    }
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    const contracts = []
    for (let i = 0; i < randomNumber(3, 6); i++)
      contracts.push(PlayerContract.withFakeData())

    const emailThreads = []
    for (let i = 0; i < randomNumber(3, 6); i++)
      emailThreads.push(PlayerEmailThread.withFakeData())

    return new Game(
      uid(),
      contracts,
      emailThreads,
      lorem.generateWords(1),
      new InMemoryUIModelAPI()
    )
  }
}

export interface GameSaveObject extends UIModelFields {
  activeSkillTitleName: string | null
}

export default Game
