import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Modal, { ModalProps } from '../Modal'

const ModalTrigger = ({
  Trigger,
  children,
  onClose,
  className,
  ...rest
}: ModalTriggerProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <div className={`${className}`}>
      <div onClick={() => setModalOpen(true)}>{Trigger}</div>
      {modalOpen && (
        <Modal
          onClose={() => {
            setModalOpen(false)
            if (onClose) onClose()
          }}
          {...rest}
        >
          {children}
        </Modal>
      )}
    </div>
  )
}

export interface ModalTriggerProps extends ModalProps {
  Trigger: JSX.Element | string
}

export default styled(observer(ModalTrigger))``
