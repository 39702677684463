import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber } from '../../../../frontend/utils/helpers/helpers'

class Email {
  content: string
  subject: string
  authorName: string
  authorImage: string
  recipients: string[]
  time: string

  constructor(
    content: string,
    subject: string,
    authorName: string,
    authorImage: string,
    recipients: string[],
    time: string
  ) {
    this.content = content
    this.subject = subject
    this.authorName = authorName
    this.authorImage = authorImage
    this.recipients = recipients
    this.time = time

    makeObservable(this, {
      content: observable,
      subject: observable,
      authorName: observable,
      authorImage: observable,
      recipients: observable,
      time: observable,
    })
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    return new Email(
      lorem.generateParagraphs(randomNumber(1, 3)),
      lorem.generateSentences(1),
      lorem.generateWords(2),
      'https://drive.google.com/uc?export=download&id=1Z6YzO8syZ1qH2O_l7fTBtn5BAN88pG8a',
      lorem.generateWords(randomNumber(1, 5)).split(' '),
      '02:00'
    )
  }
}

export default Email
