import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../utils/types'
import Button from '../Button/Button'
import UIForm from '../../../../models/UIFieldCollection/UIForm/UIForm'
import FormField from './Field/FormField'

const Form = ({
  id,
  uiForm,
  onSuccessfulSubmit = () => {},
  className,
  ...rest
}: FormProps) => {
  const submitForm = async () => {
    await uiForm.submit()
    if (!uiForm.hasErrors) onSuccessfulSubmit()
  }

  return (
    <div className={`${className}`} id={id} {...rest}>
      {uiForm.fields.map((uiField, i) => (
        <FormField
          key={uiField.name}
          uiField={uiField}
          onEnter={() => {
            if (i === uiForm.fields.length - 1) submitForm()
          }}
          className="my-4"
        />
      ))}

      <Button
        onClick={submitForm}
        loading={uiForm.isSubmitting}
        disabled={
          uiForm.hasErrors && !uiForm.fields.find((f) => f.validateSparingly)
        }
        className="form-submit-button"
        id="form-submit-button"
      >
        {uiForm.actionText}
      </Button>
      {uiForm.errors && (
        <div className="form-error-message text-error font-bold mt-4">
          {uiForm.errors.join(', ')}
        </div>
      )}
    </div>
  )
}

export interface FormProps extends HtmlDiv {
  uiForm: UIForm
  onSuccessfulSubmit?: () => void
}

export default styled(observer(Form))``
