import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber, uid } from '../../../../frontend/utils/helpers/helpers'

class Company {
  id: string
  name: string
  description: JSX.Element | string
  imageSrc: string

  constructor(
    id: string,
    name: string,
    description: JSX.Element | string,
    imageSrc: string
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.imageSrc = imageSrc

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      imageSrc: observable,
    })
  }

  static withFakeData = (): Company => {
    const lorem = new LoremIpsum()

    return new Company(
      uid(),
      lorem.generateWords(randomNumber(3, 5)),
      lorem.generateSentences(randomNumber(1, 4)),
      'https://www.sonypictures.com/sites/default/files/styles/max_360x390/public/banner-images/2020-04/stepbrothers_banner_2572x1100_v2.png'
    )
  }
}

export default Company
