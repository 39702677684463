import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv, UserInputElement } from '../../../../../utils/types'
import UIField from '../../../../../models/UIField/UIField'
import Checkbox from '../../Checkbox/Checkbox'
import DatePicker from '../../DatePicker/DatePicker'
import Input from '../../Input/Input'
import RadioGroup from '../../RadioGroup/RadioGroup'
import Select from '../../Select/Select'
import Slider from '../../Slider/Slider'
import Switch from '../../Switch/Switch'
import Textarea from '../../Textarea/Textarea'
import FormFieldControl from './Control/FormFieldControl'
import DateTimeUIField from '../../../../../models/UIField/DateTime/DateTimeUIField'
import SelectUIField from '../../../../../models/UIField/Select/SelectUIField'
import NumberUIField from '../../../../../models/UIField/Number/NumberUIField'

const FormField = ({
  uiField,
  onEnter = () => {},
  className,
  ...rest
}: FormFieldProps) => {
  const props: UserInputElement<typeof uiField.value> = {
    value: uiField.value,
    onUpdate: (v) => {
      uiField.value = v
      if (uiField.validateOnValueChange) uiField.validate()
    },
    loading: uiField.isValidating,
    name: uiField.name,
    onBlur: () => {
      if (!uiField.isValid && !uiField.validateSparingly) uiField.validate()
    },
    id: uiField.name,
    type: uiField.type,
    disabled: uiField.isValidating,
  }

  return (
    <div className={`${className}`} {...rest}>
      {uiField.type !== 'checkbox' && (
        <FormFieldControl
          key={uiField.name}
          label={uiField.displayName}
          error={uiField.hasErrors}
          caption={uiField.hasErrors ? uiField.errors.join(', ') : uiField.hint}
          className="my-2"
        >
          {uiField.type === 'date' && (
            <DatePicker
              {...props}
              onUpdate={(v) => (uiField.value = v)}
              earliestDateTime={(uiField as DateTimeUIField).earliestDateTime}
              latestDateTime={(uiField as DateTimeUIField).latestDateTime}
            />
          )}
          {uiField.type === 'radio' && (
            <RadioGroup
              {...props}
              options={(uiField as SelectUIField).selectOptions}
            />
          )}
          {uiField.type === 'select' && (
            <Select
              {...props}
              options={(uiField as SelectUIField).selectOptions}
            />
          )}
          {uiField.type === 'slider' && (
            <Slider
              {...props}
              min={(uiField as NumberUIField).min}
              max={(uiField as NumberUIField).max}
            />
          )}
          {uiField.type === 'switch' && <Switch {...props} />}
          {uiField.type === 'textarea' && <Textarea {...props} />}
          {['text', 'email', 'number', 'tel', 'password'].includes(
            uiField.type
          ) && (
            <Input
              {...props}
              onKeyPress={(e) => {
                if (e.key === 'Enter') onEnter()
              }}
            />
          )}
        </FormFieldControl>
      )}
      {uiField.type === 'checkbox' && (
        <Checkbox
          {...props}
          description={
            uiField.hasErrors ? uiField.errors.join(', ') : uiField.hint
          }
          error={uiField.hasErrors}
        >
          {uiField.displayName}
        </Checkbox>
      )}
    </div>
  )
}
export interface FormFieldProps extends HtmlDiv {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uiField: UIField<any>
  onEnter?: () => void
}

export default styled(observer(FormField))``
