import EmailUIField from '../UIField/String/Email/EmailUIField'
import PasswordUIField from '../UIField/String/Password/PasswordUIField'
import UIForm from '../UIFieldCollection/UIForm/UIForm'
import UIModelAPI from '../UIFieldCollection/UIModel/API/UIModelAPI'
import Avatar from '../Avatar/Avatar'
import { PlayerContract } from '../Game/Contract/Contract'
import { PlayerEmailThread } from '../Game/Email/Thread/EmailThread'
import Game from '../Game/Game'
import Theme from '../Theme/Theme'
import UserAPI from '../User/API/UserApi'
import User from '../User/User'
import { makeObservable, observable } from 'mobx'
import DarkTheme from '../Theme/Dark/DarkTheme'
import LocalStorageThemeAPI from '../Theme/API/LocalStorage/LocalStorageThemeAPI'
import UIError from '../UIError/UIError'
import ThemeAPI from '../Theme/API/ThemeAPI'

export enum AppEnvironments {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}
class App {
  static getEnvironment = () =>
    (process.env.ENV as AppEnvironments) || AppEnvironments.DEVELOPMENT
  user: User | null = null
  isInitializingUser = false
  uiModelAPI: UIModelAPI
  userAPI: UserAPI
  themeAPI: ThemeAPI
  _theme: Theme

  constructor(
    uiModelAPI: UIModelAPI,
    userAPI: UserAPI,
    themeAPI = new LocalStorageThemeAPI()
  ) {
    this.themeAPI = themeAPI
    this._theme = Theme.getSavedTheme(themeAPI) || new DarkTheme()
    this.uiModelAPI = uiModelAPI
    this.userAPI = userAPI

    makeObservable(this, {
      _theme: observable,
      user: observable,
      isInitializingUser: observable,
    })

    this.initializeUser()
  }

  get theme() {
    return this._theme
  }

  set theme(newTheme: Theme) {
    this._theme = newTheme
    this.themeAPI.save(newTheme)
  }

  async initializeUser() {
    try {
      this.isInitializingUser = true
      const signedInUser = await this.userAPI.getInitialUserStatus()
      if (signedInUser) {
        signedInUser.game = await Game.load(signedInUser.id, this.uiModelAPI)
        signedInUser.avatar = await Avatar.load(
          signedInUser.id,
          this.uiModelAPI
        )
        this.user = signedInUser
      }
    } catch (e) {
      this.user = null
    } finally {
      this.isInitializingUser = false
    }
  }

  async signIn(email: string, password: string) {
    const signedInUser = await this.userAPI.signIn(email, password)
    try {
      signedInUser.game = await Game.load(signedInUser.id, this.uiModelAPI)
      signedInUser.avatar = await Avatar.load(signedInUser.id, this.uiModelAPI)
      this.user = signedInUser
    } catch (e) {
      throw new UIError(
        'App_signIn',
        'Whoops, looks like an unexpected error occurred loading some of your user data, please refresh the page and try again!'
      )
    }
  }
  getSignInUIForm() {
    const emailField = new EmailUIField('Email', '')
    const passwordField = new PasswordUIField('Password', '')

    return new UIForm(
      [emailField, passwordField],
      async () => {
        await this.signIn(emailField.value, passwordField.value)
      },
      'Sign In'
    )
  }

  async signUp(email: string, password: string) {
    const signedUpUser = await this.userAPI.signUp(email, password)

    try {
      await signedUpUser.sendVerificationEmail()
      signedUpUser.game = new Game(
        signedUpUser.id,
        await PlayerContract.loadAll(signedUpUser.id, this.uiModelAPI),
        await PlayerEmailThread.loadAll(signedUpUser.id, this.uiModelAPI),
        null,
        this.uiModelAPI
      )

      await signedUpUser.game.save()

      signedUpUser.avatar = new Avatar(signedUpUser.id, '', '', this.uiModelAPI)
      this.user = signedUpUser
    } catch (e) {
      throw new UIError(
        'App_signUp',
        'Whoops, looks like there was an error initalizing some data on your new account. Please refresh the page and sign in with your new credentials.'
      )
    }
  }
  getSignUpUIForm() {
    const emailField = new EmailUIField('Email', '', {
      hint: `We'll send you a verification email when your account is created`,
    })
    const passwordField = new PasswordUIField('Password', '')

    return new UIForm(
      [emailField, passwordField],
      async () => {
        await this.signUp(emailField.value, passwordField.value)
      },
      'Sign Up'
    )
  }

  async sendPasswordRecoveryEmail(email: string) {
    await this.userAPI.sendPasswordRecoveryEmail(email)
  }
  getPasswordRecoveryForm() {
    const emailField = new EmailUIField('Email', '', {
      hint: `We'll send you an email with instructions to reset your password`,
    })

    return new UIForm(
      [emailField],
      async () => {
        await this.sendPasswordRecoveryEmail(emailField.value)
      },
      'Send Reset Email'
    )
  }

  async signOut() {
    await this.userAPI.signOut()
    this.user = null
  }
}

export default App
