import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { UserInputElement } from '../../../../utils/types'
import ReactToggle from 'react-toggle'
import 'react-toggle/style.css'
import tw from 'twin.macro'

const Switch = ({
  value,
  onUpdate,
  disabled = false,
  className,
  ...rest
}: SwitchProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <ReactToggle
        checked={value}
        onChange={(e) => onUpdate(e.target.checked)}
        disabled={disabled}
      />
    </div>
  )
}

export interface SwitchProps extends UserInputElement<boolean> {}

export default styled(observer(Switch))`
  .react-toggle--checked .react-toggle-track {
    background: ${(props) => props.theme.background.colors.primary.base};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background: ${(props) => props.theme.background.colors.primary.base};
  }

  .react-toggle--checked .react-toggle-thumb {
    border-color: ${(props) => props.theme.background.colors.primary.base};
  }

  .react-toggle--focus .react-toggle-thumb {
    ${tw`shadow-none`}
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    ${tw`shadow-none`}
  }
`
