import { makeObservable, observable, reaction } from 'mobx'
import UIError from '../../UIError/UIError'
import UIField from '../../UIField/UIField'
import UIFieldCollection from '../UIFieldCollection'
import UIModelAPI from './API/UIModelAPI'

class UIModel extends UIFieldCollection {
  id: string
  collection: string
  isRemoving = false
  isSaving = false
  isSaved: boolean
  uiModelAPI: UIModelAPI

  constructor(
    id: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: UIField<any>[],
    collection: string,
    uiModelAPI: UIModelAPI,
    options?: UIModelOptions
  ) {
    super(fields)

    this.id = id
    this.collection = collection
    this.uiModelAPI = uiModelAPI
    this.isSaved = options?.isSaved || false

    makeObservable(this, {
      id: observable,
      collection: observable,
      isSaved: observable,
      isSaving: observable,
      isRemoving: observable,
    })

    reaction(
      () => this.toSaveObject(),
      () => (this.isSaved = false)
    )
  }

  async save() {
    try {
      this.isSaving = true
      await this.validate()
      if (this.isValid) {
        await this.uiModelAPI.set(this.id, this.toSaveObject(), this.collection)
        this.isSaved = true
      } else
        throw new UIError(
          'UIModel_save',
          'Unable to save, one or more fields have errors'
        )
    } finally {
      this.isSaving = false
    }
  }

  async remove() {
    try {
      this.isRemoving = true
      await this.uiModelAPI.remove(this.id, this.collection)
      this.isSaved = false
    } finally {
      this.isRemoving = false
    }
  }

  toSaveObject(): UIModelFields {
    const saveObject: UIModelFields = { id: this.id }
    this.fields.forEach((field) => {
      saveObject[field.name] = field.value
    })
    return saveObject
  }
}

export interface UIModelFields extends Record<string, unknown> {
  id: string
}

export interface UIModelOptions {
  isSaved?: boolean
}

export default UIModel
