import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber } from '../../../../utils/helpers/helpers'
import Skill from '../Skill'

class SkillTitle {
  name: string
  prerequisiteSkills: Skill[]

  constructor(name: string, prerequisiteSkills: Skill[]) {
    this.name = name
    this.prerequisiteSkills = prerequisiteSkills

    makeObservable(this, {
      name: observable,
      prerequisiteSkills: observable,
    })
  }

  isUnlocked(playerSkills: Skill[]) {
    let hasQualifyingSkills = true
    this.prerequisiteSkills.forEach((skill) => {
      const skillToCheck = playerSkills.find((s) => s.name === skill.name)
      if (!skillToCheck || skillToCheck.xp < skill.xp)
        hasQualifyingSkills = false
    })
    return hasQualifyingSkills
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    return new SkillTitle(lorem.generateWords(randomNumber(1, 3)), [])
  }
}

export const skillTitles: SkillTitle[] = []

export default SkillTitle
