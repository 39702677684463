import { makeObservable, observable } from 'mobx'

class UIField<ValueType> {
  errors: string[] = []
  name: string
  type: UIFieldType
  hint: string
  displayName: string
  validateOnValueChange: boolean
  validateSparingly: boolean
  isValid = false
  isValidating = false
  _value: ValueType
  private _format: (unformattedValue: ValueType) => ValueType
  private _validate: () => Promise<string[]> | string[]

  constructor(
    name: string,
    type: UIFieldType,
    value: ValueType,
    options?: UIFieldOptions<ValueType>
  ) {
    this.name = name
    this.type = type
    this.hint = options?.hint || ''
    this.displayName = options?.displayName || name
    this.validateOnValueChange = options?.validateOnValueChange || false
    this.validateSparingly = options?.validateSparingly || false
    this._format =
      options?.format || ((unformattedValue: ValueType) => unformattedValue)
    this._validate = options?.validate || (() => [])
    this._value = this._format(value)

    makeObservable(this, {
      _value: observable,
      name: observable,
      hint: observable,
      displayName: observable,
      validateOnValueChange: observable,
      errors: observable,
      isValidating: observable,
      isValid: observable,
      validateSparingly: observable,
    })
  }

  get hasErrors() {
    return this.errors.length > 0
  }

  get value() {
    return this._value
  }

  set value(newValue: ValueType) {
    this.isValid = false
    this._value = this._format(newValue)
    if (this.validateOnValueChange) this.validate()
  }

  async validate() {
    this.isValidating = true
    this.errors = []
    try {
      this.errors.push(...(await this._validate()))
    } catch (e) {
      this.errors.push(
        e.message ||
          `An unexpected error occurred validating the ${this.name}, please refresh the page and try again`
      )
    } finally {
      this.isValidating = false
      if (!this.hasErrors) this.isValid = true
    }
  }
}

export default UIField

export type UIFieldType =
  | 'text'
  | 'password'
  | 'email'
  | 'tel'
  | 'number'
  | 'checkbox'
  | 'textarea'
  | 'slider'
  | 'date'
  | 'switch'
  | 'radio'
  | 'select'

export interface UIFieldOptions<ValueType> {
  format?: (unformattedValue: ValueType) => ValueType
  validate?: () => Promise<string[]> | string[]
  hint?: string
  displayName?: string
  validateOnValueChange?: boolean
  validateSparingly?: boolean
}
