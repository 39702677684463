import colorPallets from '../ColorPallets'
import Theme from '../Theme'

class DarkTheme extends Theme {
  static id = 'dark'

  constructor() {
    const primaryColorPallet = colorPallets.purple
    super({
      id: DarkTheme.id,
      primaryColorPallet,
      text: {
        colors: {
          base: colorPallets.gray[100],
          bold: '#ffffff',
          accent: colorPallets.gray[500],
          primary: {
            base: primaryColorPallet[500],
            bold: primaryColorPallet[700],
            accent: primaryColorPallet[400],
          },
          highlight: colorPallets.yellow[700],
          link: primaryColorPallet[500],
          error: colorPallets.red[400],
          warning: colorPallets.orange[400],
          success: colorPallets.green[400],
          light: colorPallets.gray[100],
          dark: colorPallets.gray[800],
        },
      },
      background: {
        colors: {
          base: colorPallets.gray[800],
          bold: colorPallets.gray[900],
          accent: colorPallets.gray[700],
          primary: {
            base: primaryColorPallet[500],
            bold: primaryColorPallet[700],
            accent: colorPallets.gray[900],
          },
          error: colorPallets.red[500],
          warning: colorPallets.orange[500],
          success: colorPallets.green[500],
          light: colorPallets.gray[100],
          dark: colorPallets.gray[900],
        },
      },
    })
  }
}

export default DarkTheme
