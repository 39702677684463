import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../utils/types'
import Form from '../Core/Inputs/Form/Form'
import useApp from '../AppContextProvider/hooks/useApp'

const Auth = ({ className, ...rest }: AuthProps) => {
  const [authAction, setAuthAction] = useState<AUTH_ACTIONS>(
    AUTH_ACTIONS.SIGN_UP
  )
  const app = useApp()
  return (
    <div className={`${className}`} {...rest}>
      <div className="text-xl font-bold">{authAction}</div>
      {authAction === AUTH_ACTIONS.SIGN_IN && (
        <div className="w-full">
          <Form uiForm={app.getSignInUIForm()} />
          <br />
          <div className="text-sm">
            No account?{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => setAuthAction(AUTH_ACTIONS.SIGN_UP)}
            >
              {AUTH_ACTIONS.SIGN_UP}
            </span>
          </div>
          <div className="text-sm">
            Forgot password?{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => setAuthAction(AUTH_ACTIONS.RECOVER_PASSWORD)}
            >
              Reset
            </span>
          </div>
        </div>
      )}
      {authAction === AUTH_ACTIONS.SIGN_UP && (
        <div className="w-full">
          <Form uiForm={app.getSignUpUIForm()} />
          <br />
          <div className="text-sm">
            Already have an account?{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => setAuthAction(AUTH_ACTIONS.SIGN_IN)}
            >
              {AUTH_ACTIONS.SIGN_IN}
            </span>
          </div>
        </div>
      )}
      {authAction === AUTH_ACTIONS.RECOVER_PASSWORD && (
        <div className="w-full">
          <Form uiForm={app.getPasswordRecoveryForm()} />
          <br />
          <div className="text-sm">
            <span
              className="underline cursor-pointer"
              onClick={() => setAuthAction(AUTH_ACTIONS.SIGN_IN)}
            >
              {AUTH_ACTIONS.SIGN_IN}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export interface AuthProps extends HtmlDiv {}

enum AUTH_ACTIONS {
  SIGN_UP = 'Create an Account',
  SIGN_IN = 'Sign In',
  RECOVER_PASSWORD = 'Reset Your Password',
}

export default styled(observer(Auth))``
