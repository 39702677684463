import StringUIField, {
  StringUIFieldOptions,
} from '../../UIField/String/StringUIField'
import UIModelAPI from '../../UIFieldCollection/UIModel/API/UIModelAPI'
import Avatar from '../Avatar'

class AvatarNameUIField extends StringUIField {
  constructor(
    name: string,
    avatarName: string,
    avatarId: string,
    uiModelAPI: UIModelAPI,
    options?: StringUIFieldOptions
  ) {
    super(name, 'text', avatarName, {
      ...options,
      displayName: 'Avatar Name',
      minLength: 3,
      maxLength: 25,
      maxWords: 1,
      format: AvatarNameUIField.formatName,
      hint: 'a-z, 0-9, _-, no spaces',
      validateSparingly: true,
      validate: async () => {
        const errors = []
        const avatarNameAvailable = await AvatarNameUIField.isNameAvailable(
          this.value,
          avatarId,
          uiModelAPI
        )
        if (!avatarNameAvailable)
          errors.push('The avatar name is already taken')
        return errors
      },
    })
  }

  static formatName(unformattedName: string) {
    const validCharacters = 'abcdefghijklmnopqrstuvwxyz_-1234567890'

    return unformattedName
      .split('')
      .filter(
        (c) =>
          validCharacters.includes(c) ||
          validCharacters.toUpperCase().includes(c)
      )
      .join('')
      .slice(0, 25)
      .toLowerCase()
  }

  static async isNameAvailable(
    name: string,
    id: string,
    uiModelAPI: UIModelAPI
  ) {
    const avatarsWithAvatarName = await uiModelAPI.getAll(Avatar.collection, [
      ['avatarName', '==', name],
    ])
    return (
      avatarsWithAvatarName.length === 0 ||
      avatarsWithAvatarName.map((n) => n.id).includes(id)
    )
  }
}

export default AvatarNameUIField
