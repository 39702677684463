import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { UserInputElement } from '../../../../utils/types'

const Textarea = ({
  value,
  onUpdate,
  disabled = false,
  placeholder,
  className,
  ...rest
}: TextareaProps) => {
  return (
    <div {...rest}>
      <textarea
        className={`rounded-lg outline-none p-3 w-full ${
          disabled ? 'cursor-not-allowed bg-accent' : 'bg-bold'
        } ${className}`}
        value={value}
        onChange={(e) => {
          if (!disabled) onUpdate(e.target.value)
        }}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  )
}

export interface TextareaProps extends UserInputElement<string> {
  placeholder?: string
}

export default styled(observer(Textarea))`
  border: 0.2rem solid ${(props) => props.theme.background.colors.primary.base};
  height: ${(props) => props.theme.text.lineHeight * 8}px;
`
