import { loadStripe, Stripe } from '@stripe/stripe-js'

let stripe: Stripe

export const getStripe = async () => {
  if (!stripe) {
    stripe = (await loadStripe(process.env.STRIPE_KEY || '')) as Stripe
  }
  return stripe
}
