import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { UserInputElement } from '../../../../utils/types'
import Spinner from '../../Spinner/Spinner'

const Checkbox = ({
  children,
  value,
  onUpdate,
  disabled = false,
  loading = false,
  description,
  error = false,
  className,
  ...rest
}: CheckboxProps) => {
  return (
    <div className={` flex flex-wrap ${className}`} {...rest}>
      <div
        className={`box mr-3 p-2 box-border rounded flex items-center justify-center ${
          disabled || loading ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={() => {
          if (!disabled && !loading) onUpdate(!value)
        }}
      >
        {value && !loading && (
          <div
            className={`check ${
              disabled ? 'bg-accent' : 'bg-primary'
            } flex-shrink-0`}
          ></div>
        )}
        {loading && <Spinner size="0.75rem" />}
      </div>
      <div>
        <div>{children}</div>
        {description && (
          <div className={`text-xs ${error ? 'text-error' : 'text-accent'}`}>
            {description}
          </div>
        )}
      </div>
    </div>
  )
}

export interface CheckboxProps extends UserInputElement<boolean> {
  description?: string
  error?: boolean
}

export default styled(observer(Checkbox))`
  > .box {
    border: 0.2rem solid
      ${(props) =>
        props.disabled
          ? props.theme.background.colors.accent
          : props.theme.text.colors.base};
    width: 0.75rem;
    height: 0.75rem;

    .check {
      width: 0.733rem;
      height: 0.733rem;
      border-radius: 0.1rem;
    }
  }
`
