import StringUIField, { StringUIFieldOptions } from '../StringUIField'

class PasswordUIField extends StringUIField {
  constructor(name: string, value: string, options?: StringUIFieldOptions) {
    super(name, 'password', value, {
      ...options,
      minWords: 1,
    })
  }
}

export default PasswordUIField
