import { useContext } from 'react'
import { AppContext } from '../AppContextProvider'

const useApp = () => {
  const { app } = useContext(AppContext) as AppContext

  return app
}

export default useApp
