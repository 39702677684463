import { makeObservable, observable } from 'mobx'
import UIField, { UIFieldOptions } from '../UIField'

class StringUIField extends UIField<string> {
  minLength: number
  maxLength: number
  minWords: number
  maxWords: number
  match: RegExp

  constructor(
    name: string,
    type: 'text' | 'password' | 'email' | 'tel' | 'textarea',
    value: string,
    options?: StringUIFieldOptions
  ) {
    super(name, type, value, {
      ...options,
      validate: async () => {
        const errors = []
        if (options?.validate) errors.push(...(await options.validate()))

        if (this.value.length < this.minLength)
          errors.push(`Must be at least ${this.minLength} characters`)
        if (this.value.length > this.maxLength)
          errors.push(`Must not exceed ${this.maxLength} characters`)

        const numWords = this.value.split(' ').length
        if (numWords < this.minWords)
          errors.push(`Must be at least ${this.minWords} words`)
        if (numWords > this.maxWords)
          errors.push(`Must not exceed ${this.maxWords} words`)

        if (this.value.match(this.match) === null)
          errors.push(`${this.displayName} is formatted incorrectly`)

        return errors
      },
    })

    this.minLength = options?.minLength || 0
    this.maxLength = options?.maxLength || 1000000000
    this.minWords = options?.minWords || 0
    this.maxWords = options?.maxWords || 1000000000
    this.match = options?.match || /.*/

    makeObservable(this, {
      minLength: observable,
      maxLength: observable,
      minWords: observable,
      maxWords: observable,
      match: observable,
    })
  }
}

export default StringUIField

export interface StringUIFieldOptions extends UIFieldOptions<string> {
  minLength?: number
  maxLength?: number
  minWords?: number
  maxWords?: number
  match?: RegExp
}
