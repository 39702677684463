import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { SelectInputElement } from '../../../../utils/types'
import Radio from './Radio/Radio'

const RadioGroup = ({
  value,
  onUpdate,
  disabled = false,
  options,
  className,
  ...rest
}: RadioGroupProps) => {
  return (
    <div className={`${className}`} {...rest}>
      {options.map((option) => (
        <Radio
          key={option.value}
          selected={value === option.value}
          onSelect={() => {
            if (!disabled) onUpdate(option.value)
          }}
          description={option.hint}
          disabled={disabled}
          className="my-2"
        >
          {option.label}
        </Radio>
      ))}
    </div>
  )
}

export interface RadioGroupProps extends SelectInputElement {}

export default styled(observer(RadioGroup))``
