import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { uid } from '../../utils/helpers/helpers'
import UIError from '../UIError/UIError'
import UIForm from '../UIFieldCollection/UIForm/UIForm'
import InMemoryUIModelAPI from '../UIFieldCollection/UIModel/API/InMemory/InMemoryUIModelAPI'
import UIModelAPI from '../UIFieldCollection/UIModel/API/UIModelAPI'
import UIModel, {
  UIModelFields,
  UIModelOptions,
} from '../UIFieldCollection/UIModel/UIModel'
import AvatarNameUIField from './NameUIField/AvatarNameUIField'

class Avatar extends UIModel {
  avatarName: AvatarNameUIField
  appearanceId: string
  static collection = 'profiles'

  constructor(
    id: string,
    avatarName: string,
    appearanceId: string,
    uiModelAPI: UIModelAPI,
    options?: UIModelOptions
  ) {
    const avatarNameField = new AvatarNameUIField(
      'avatarName',
      avatarName,
      id,
      uiModelAPI
    )

    super(id, [avatarNameField], Avatar.collection, uiModelAPI, options)
    this.avatarName = avatarNameField
    this.appearanceId = appearanceId

    makeObservable(this, {
      avatarName: observable,
      appearanceId: observable,
    })
  }

  static async load(id: string, uiModelAPI: UIModelAPI) {
    const savedAvatar = ((await uiModelAPI.get(
      id,
      Avatar.collection
    )) as unknown) as AvatarSaveObject
    if (savedAvatar)
      return new Avatar(
        savedAvatar.id,
        savedAvatar.avatarName,
        savedAvatar.appearanceId,
        uiModelAPI,
        { isSaved: true }
      )
    return new Avatar(id, '', '', uiModelAPI)
  }

  toSaveObject(): AvatarSaveObject {
    return {
      ...super.toSaveObject(),
      appearanceId: this.appearanceId,
    } as AvatarSaveObject
  }

  getAvatarNameForm() {
    const newAvatarNameField = new AvatarNameUIField(
      'Avatar Name',
      this.avatarName.value,
      this.id,
      this.uiModelAPI,
      { hint: this.avatarName.hint }
    )
    return new UIForm(
      [newAvatarNameField],
      async () => {
        this.avatarName.value = newAvatarNameField.value
        try {
          await this.save()
        } catch (e) {
          throw new UIError(
            'Avatar_getAvatarNameForm',
            'Whoops, looks like there was an error setting your avatar name. Please refresh the page and try again!'
          )
        }
        alert(
          `You did it! We'll see you in the game soon ${this.avatarName.value}!`
        )
      },
      'Set Name'
    )
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    return new Avatar(
      uid(),
      lorem.generateWords(1),
      uid(),
      new InMemoryUIModelAPI()
    )
  }
}

export interface AvatarSaveObject extends UIModelFields {
  avatarName: string
  appearanceId: string
}

export default Avatar
