import type firebase from 'firebase'
import Firebase from '../../../../Firebase/Firebase'
import UIError from '../../../../UIError/UIError'
import UIModelAPI, { UIModelFilters } from '../UIModelAPI'

class FirebaseUIModelAPI implements UIModelAPI {
  firebase: Firebase

  constructor(firebase: Firebase) {
    this.firebase = firebase
  }

  async get(id: string, collection: string) {
    try {
      const docRef = this.firebase.firestore.collection(collection).doc(id)
      const doc = await docRef.get()
      const docData = doc.data()
      if (doc.exists && docData) {
        return {
          id,
          ...docData,
        }
      } else return null
    } catch (e) {
      throw new UIError(
        'FirebaseUIModelAPI_get',
        getFirestoreErrorMessage(e.message, 'Failed to load data from server'),
        e
      )
    }
  }

  async getAll(collection: string, filters?: UIModelFilters) {
    try {
      const documentRef = filters
        ? await this.getRefWithFilters(filters, collection).get()
        : await this.firebase.firestore.collection(collection).get()

      return documentRef.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    } catch (e) {
      throw new UIError(
        'FirebaseUIModelAPI_getAll',
        getFirestoreErrorMessage(e.message, 'Failed to load data from server'),
        e
      )
    }
  }

  async set(id: string, data: Record<string, unknown>, collection: string) {
    try {
      const documentRef = await this.firebase.firestore
        .collection(collection)
        .doc(id)
      return await documentRef.set(data, {
        merge: true,
      })
    } catch (e) {
      throw new UIError(
        'FirebaseUIModelAPI_set',
        getFirestoreErrorMessage(e.message, 'Failed to save data to server'),
        e
      )
    }
  }

  async remove(id: string, collection: string) {
    try {
      const documentRef = await this.firebase.firestore
        .collection(collection)
        .doc(id)
      return await documentRef.delete()
    } catch (e) {
      throw new UIError(
        'FirebaseUIModelAPI_remove',
        getFirestoreErrorMessage(
          e.message,
          'Failed to remove data from server'
        ),
        e
      )
    }
  }

  private getRefWithFilters = (filters: UIModelFilters, collection: string) => {
    const currentRef = this.firebase.firestore.collection(collection)
    return filters.reduce<
      firebase.firestore.Query<firebase.firestore.DocumentData>
    >(
      (acc, filter) =>
        acc.where(
          filter[0] as string,
          filter[1] as firebase.firestore.WhereFilterOp,
          filter[2]
        ),
      currentRef
    )
  }
}

const getFirestoreErrorMessage = (
  errorCode: string,
  fallbackMessage = 'An unexpected error has occurred, please refresh the page and try again.'
) => {
  return FIRESTORE_ERROR_CODES[errorCode] || fallbackMessage
}

export const FIRESTORE_ERROR_CODES: Record<string, string> = {
  'not-found': 'The requested data was not found',
  'permission-denied': 'Unauthorized',
  unauthenticated: 'Unauthorized, please login.',
}

export default FirebaseUIModelAPI
