import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import { makeObservable, observable } from 'mobx'
import { uid } from '../../utils/helpers/helpers'

export const config = {
  apiKey: process.env.FB_API_KEY,
  authDomain: process.env.FB_AUTH_DOMAIN,
  databaseURL: process.env.FB_DATABASE_URL,
  projectId: process.env.FB_PROJECT_ID,
  storageBucket: process.env.FB_STORAGE_BUCKET,
  messagingSenderId: process.env.FB_MESSAGING_SENDER_ID,
  appId: process.env.FB_APP_ID,
  measurementId: process.env.FB_MEASUREMENT_ID,
}

class Firebase {
  // @ts-ignore
  app: firebase.app.App
  // @ts-ignore
  firestore: firebase.firestore.Firestore
  // @ts-ignore
  auth: firebase.auth.Auth
  // @ts-ignore
  functions: firebase.functions.Functions
  // @ts-ignore
  readonly usingEmulators: boolean

  constructor(useEmulators = false) {
    if (Object.keys(firebase).length > 0) {
      try {
        firebase.app('[DEFAULT]')
      } catch (e) {
        firebase.initializeApp(config)
      }

      this.app = useEmulators
        ? firebase.initializeApp(config, uid())
        : firebase.app()
      this.firestore = this.app.firestore()
      this.auth = this.app.auth()
      this.functions = this.app.functions()
      this.usingEmulators = useEmulators

      if (typeof window !== 'undefined' && firebase.apps.length === 1)
        this.app.analytics()

      this.firestore.settings({ experimentalForceLongPolling: true })

      if (this.usingEmulators) {
        this.firestore.useEmulator('localhost', 5002)
        this.functions.useEmulator('localhost', 5001)
        this.auth.useEmulator('http://localhost:5006')
      }
      makeObservable(this, {
        app: observable,
        firestore: observable,
        auth: observable,
        functions: observable,
        usingEmulators: observable,
      })
    }
  }
}

export default Firebase
