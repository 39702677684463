import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiOutlineWarning,
  AiOutlineDown,
  AiOutlineUp,
  AiFillSetting,
  AiFillEdit,
  AiFillCalendar,
  AiFillHome,
  AiFillLock,
  AiFillEye,
  AiFillEyeInvisible,
} from 'react-icons/ai'
import { FiLogOut } from 'react-icons/fi'
import { FaHamburger } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { HiOfficeBuilding } from 'react-icons/hi'
import { IconType } from 'react-icons/lib'
import { observer } from 'mobx-react-lite'
import { FaMoon, FaSun } from 'react-icons/fa'

const Icon = ({ name, className, ...rest }: IconProps) => {
  const TargetIcon = ICONS[name]
  return (
    <div {...rest}>
      <TargetIcon className={`${className}`} />
    </div>
  )
}

export type IconName =
  | 'success'
  | 'error'
  | 'info'
  | 'close'
  | 'warning'
  | 'downArrow'
  | 'upArrow'
  | 'settings'
  | 'edit'
  | 'sun'
  | 'moon'
  | 'complete'
  | 'calendar'
  | 'home'
  | 'office'
  | 'lock'
  | 'show'
  | 'hide'
  | 'hamburger'
  | 'signOut'

export const ICONS: Record<IconName, IconType> = {
  success: AiFillCheckCircle,
  error: AiOutlineWarning,
  warning: AiOutlineWarning,
  info: AiFillInfoCircle,
  close: IoMdClose,
  downArrow: AiOutlineDown,
  upArrow: AiOutlineUp,
  settings: AiFillSetting,
  edit: AiFillEdit,
  sun: FaSun,
  moon: FaMoon,
  complete: AiFillCheckCircle,
  calendar: AiFillCalendar,
  home: AiFillHome,
  office: HiOfficeBuilding,
  lock: AiFillLock,
  show: AiFillEye,
  hide: AiFillEyeInvisible,
  hamburger: FaHamburger,
  signOut: FiLogOut,
}

export interface IconProps extends HtmlDiv {
  name: IconName
}

export default styled(observer(Icon))``
