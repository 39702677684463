import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'
import { UserInputElement } from '../../../../utils/types'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'

const DatePicker = ({
  value,
  onUpdate,
  disabled = false,
  earliestDateTime,
  latestDateTime,
  className,
  ...rest
}: DatePickerProps) => {
  return (
    <div className={`${className}`} {...rest} onBlur={() => {}}>
      <ReactDatePicker
        selected={value.toJSDate()}
        onChange={(selectedDate: Date) => {
          if (!disabled) onUpdate(DateTime.fromJSDate(selectedDate))
        }}
        disabled={disabled}
        wrapperClassName="date-picker"
        calendarClassName="date-picker-calendar"
        minDate={earliestDateTime?.toJSDate()}
        maxDate={latestDateTime?.toJSDate()}
      />
    </div>
  )
}

export interface DatePickerProps extends UserInputElement<DateTime> {
  earliestDateTime?: DateTime
  latestDateTime?: DateTime
}

export default styled(observer(DatePicker))`
  > .date-picker {
    input {
      ${tw`p-1 rounded-lg outline-none`}
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
      border: 0.2rem solid
        ${(props) => props.theme.background.colors.primary.base};
      background: ${(props) =>
        props.disabled
          ? props.theme.background.colors.accent
          : props.theme.background.colors.bold} !important;
      color: ${(props) =>
        props.disabled
          ? props.theme.text.colors.accent
          : props.theme.text.colors.base} !important;
    }
  }

  .date-picker-calendar {
    background: ${(props) => props.theme.background.colors.bold} !important;

    .react-datepicker__day {
      ${tw`font-bold`}
      color: ${(props) => props.theme.text.colors.base} !important;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background: ${(props) =>
        props.theme.background.colors.primary.base} !important;
      color: ${(props) => props.theme.text.colors.light} !important;
    }
    .react-datepicker__month-container {
      font-family: ${(props) => props.theme.text.fonts.primary};
      .react-datepicker__header {
        background: ${(props) =>
          props.theme.background.colors.primary.base} !important;
        .react-datepicker__current-month,
        .react-datepicker__day-name {
          color: ${(props) => props.theme.text.colors.light} !important;
        }
      }
    }
  }
`
