import ThemeAPI from './API/ThemeAPI'
import { ColorPallet } from './ColorPallets'

class Theme implements ThemeValues {
  readonly id: string
  readonly primaryColorPallet: ColorPallet
  readonly text: {
    readonly fonts: {
      readonly primary: string
      readonly secondary: string
    }
    readonly baseSize: number
    readonly lineHeight: number
    readonly colors: {
      readonly base: string
      readonly bold: string
      readonly accent: string
      readonly primary: {
        readonly base: string
        readonly bold: string
        readonly accent: string
      }
      readonly highlight: string
      readonly link: string
      readonly error: string
      readonly warning: string
      readonly success: string
      readonly light: string
      readonly dark: string
    }
  }
  readonly background: {
    readonly colors: {
      readonly base: string
      readonly bold: string
      readonly accent: string
      readonly primary: {
        readonly base: string
        readonly bold: string
        readonly accent: string
      }
      readonly error: string
      readonly warning: string
      readonly success: string
      readonly light: string
      readonly dark: string
    }
  }
  readonly screenSizes: {
    readonly xs: number
    readonly sm: number
    readonly md: number
    readonly lg: number
    readonly xl: number
    readonly xl2: number
  }

  constructor(themeValues: ThemeValues) {
    this.id = themeValues.id
    this.primaryColorPallet = themeValues.primaryColorPallet
    this.text = {
      ...themeValues.text,
      fonts: themeValues.text.fonts || {
        primary: 'Source Code Pro',
        secondary: 'Axiforma',
      },
      baseSize: themeValues.text.baseSize || 17,
      lineHeight: themeValues.text.lineHeight || 17 + 17 * 0.2,
    }
    this.background = themeValues.background
    this.screenSizes = themeValues.screenSizes || {
      xs: 320,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xl2: 1536,
    }
  }

  toCSS() {
    const highlightBar = `
content: ' ';
position: absolute;
z-index: -1;
width: 100%;
left: 0.25rem;
`
    return `
html {
  font-family: ${this.text.fonts.primary};
  font-size: ${this.text.baseSize}px;
  line-height: ${this.text.lineHeight}px;
  color: ${this.text.colors.base};
  background: ${this.background.colors.base};
}
-webkit-tap-highlight-color: transparent !important;
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  display: table;
  position: relative;
  line-height: 1.25rem;
  scroll-margin-top: 10rem;
}
  

h1 {
  font-size: 3rem;
  line-height: 1;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h5 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
h6 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul {
  margin-left: 2rem;
}

li {
  list-style-type: disc;
}

a { text-decoration: none; }

.font-primary { font-family: ${this.text.fonts.primary}; }
.font-secondary { font-family: ${this.text.fonts.secondary}; }

.w-xs { width: ${this.screenSizes.xs}px; }
.max-w-xs { max-width: ${this.screenSizes.xs}px; }
.min-w-xs { min-width: ${this.screenSizes.xs}px; }

.w-sm { width: ${this.screenSizes.sm}px; }
.max-w-sm { max-width: ${this.screenSizes.sm}px; }
.min-w-sm { min-width: ${this.screenSizes.sm}px; }

.w-md { width: ${this.screenSizes.md}px; }
.max-w-md { max-width: ${this.screenSizes.md}px; }
.min-w-md { min-width: ${this.screenSizes.md}px; }

.w-lg { width: ${this.screenSizes.lg}px; }
.max-w-lg { max-width: ${this.screenSizes.lg}px; }
.min-w-lg { min-width: ${this.screenSizes.lg}px; }

.w-xl { width: ${this.screenSizes.xl}px; }
.max-w-xl { max-width: ${this.screenSizes.xl}px; }
.min-w-xl { min-width: ${this.screenSizes.xl}px; }

.bg-base { background: ${this.background.colors.base}; }
.bg-accent { background: ${this.background.colors.accent}; }
.bg-bold { background: ${this.background.colors.bold}; }
.bg-primary { background: ${this.background.colors.primary.base}; }
.bg-primary-accent { background: ${this.background.colors.primary.accent}; }
.bg-primary-bold { background: ${this.background.colors.primary.bold}; }
.bg-error { background: ${this.background.colors.error}; }
.bg-warning { background: ${this.background.colors.warning}; }
.bg-success { background: ${this.background.colors.success}; }
.bg-light { background: ${this.background.colors.light}; }
.bg-dark { background: ${this.background.colors.dark}; }
.bg-text { background: ${this.text.colors.base}; }

.text-base { color: ${this.text.colors.base}; }
.text-accent { color: ${this.text.colors.accent}; }
.text-bold { color: ${this.text.colors.bold}; }
.text-primary { color: ${this.text.colors.primary.base}; }
.text-primary-bold { color: ${this.text.colors.primary.bold}; }
.text-primary-accent { color: ${this.text.colors.primary.accent}; }
.text-error { color: ${this.text.colors.error}; }
.text-warning { color: ${this.text.colors.warning}; }
.text-success { color: ${this.text.colors.success}; }
.text-highlight { color: ${this.text.colors.highlight}; }
.text-link { color: ${this.text.colors.link}; }
.text-light { color: ${this.text.colors.light}; }
.text-dark { color: ${this.text.colors.dark}; }
.text-bg { color: ${this.background.colors.base}; }

.sticky-offset { top: 4rem; }

.highlight { 
  display: table;
  position: relative;
}

.highlight::before {
  ${highlightBar}
  height: 50%;
  bottom: 0.25rem;
  background: ${this.background.colors.primary.base};
}

.inline-highlight {
  background: ${this.background.colors.primary.base};
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
`
  }

  static getSavedTheme(themeAPI: ThemeAPI) {
    const savedTheme = themeAPI.load()
    return savedTheme
  }
}

export default Theme

interface ThemeValues {
  id: string
  primaryColorPallet: ColorPallet
  text: {
    fonts?: {
      primary: string
      secondary: string
    }
    baseSize?: number
    lineHeight?: number
    colors: {
      base: string
      bold: string
      accent: string
      primary: {
        base: string
        bold: string
        accent: string
      }
      highlight: string
      link: string
      error: string
      warning: string
      success: string
      light: string
      dark: string
    }
  }
  background: {
    colors: {
      base: string
      bold: string
      accent: string
      primary: {
        base: string
        bold: string
        accent: string
      }
      error: string
      warning: string
      success: string
      light: string
      dark: string
    }
  }
  screenSizes?: {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    xl2: number
  }
}
