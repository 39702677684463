import React from 'react'
import styled from 'styled-components'
import { SelectInputElement } from '../../../../utils/types'
import ReactSelect from 'react-select'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'
ReactSelect
const Select = ({
  value,
  options,
  onUpdate,
  disabled = false,
  clearable = true,
  searchable = true,
  className,
  ...rest
}: SelectProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <ReactSelect
        value={{
          value,
          label: options.find((o) => o.value === value)?.label || value,
        }}
        options={options}
        onChange={(selectedOption) => {
          onUpdate(selectedOption?.value || '')
        }}
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        classNamePrefix={`select`}
      />
    </div>
  )
}

export interface SelectProps extends SelectInputElement {
  clearable?: boolean
  searchable?: boolean
}

export default styled(observer(Select))`
  .select__control {
    ${tw`cursor-pointer`}
    background: ${(props) => props.theme.background.colors.bold} !important;
    border: 0.2rem solid
      ${(props) => props.theme.background.colors.primary.base} !important;
  }
  .select__menu {
    background: ${(props) => props.theme.background.colors.bold} !important;
    border: 0.2rem solid
      ${(props) => props.theme.background.colors.primary.base} !important;
  }

  .select__single-value,
  .select__indicator,
  .select__input {
    color: ${(props) => props.theme.text.colors.base} !important;
  }

  .select__option--is-focused {
    ${tw`cursor-pointer`}
    background: ${(props) => props.theme.background.colors.accent} !important;
  }

  .select__option--is-selected {
    background: ${(props) =>
      props.theme.background.colors.primary.base} !important;
  }
`
