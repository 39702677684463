import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import Icon from '../../Content/Icon/Icon'

const Modal = ({
  name = '',
  onClose = () => {},
  children,
  closeOnClickOff = true,
  className,
  ...rest
}: ModalProps) => {
  const modalBodyContents = useRef<HTMLDivElement>(null)
  const [modalBodyMaxHeight, setModalBodyMaxHeight] = useState('100%')

  useEffect(() => {
    const height = modalBodyContents.current?.getBoundingClientRect().height
    if (height && height > 300)
      setModalBodyMaxHeight(`${height + height * 0.25}px` || '100%')
  }, [])

  return (
    <div
      className={`fixed flex z-20 justify-center items-start box-border fixed w-full ${
        closeOnClickOff && 'cursor-pointer'
      } inset-0 ${className}`}
      onClick={() => {
        if (closeOnClickOff) onClose()
      }}
      {...rest}
    >
      <div
        className={`modal-body overflow-hidden rounded-xl shadow-lg mx-4 mt-4 box-border cursor-default relative bg-base `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center bg-primary px-4 py-2 text-lg font-bold text-light">
          <div className="flex-grow mr-2">{name}</div>
          <Icon
            name="close"
            className="text-xl font-bold cursor-pointer right-0 top-0"
            id="modal_close-button"
            onClick={onClose}
          />
        </div>
        <div
          className={`overflow-scroll p-3`}
          style={{ maxHeight: modalBodyMaxHeight }}
        >
          <div ref={modalBodyContents}> {children}</div>
          <div className="lg:hidden" style={{ height: modalBodyMaxHeight }} />
        </div>
      </div>

      <div className="backdrop fixed w-full h-screen bg-accent -z-10"></div>
    </div>
  )
}

export interface ModalProps extends HtmlDiv {
  onClose?: () => void
  name?: string
  closeOnClickOff?: boolean
}

export default styled(observer(Modal))`
  height: 99vh;
  .modal-body {
    animation: slidein-modal;
    animation-duration: 0.25s;
  }
  .backdrop {
    opacity: 0.75;
  }

  @keyframes slidein-modal {
    0% {
      transform: translateY(2rem);
    }
  }
`
