module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/simulator.dev/simulator.dev/node_modules/gatsby-remark-images","id":"37e08fc3-411c-54a4-a388-bf5cec15a6c0","name":"gatsby-remark-images","version":"3.11.1","pluginOptions":{"plugins":[],"maxWidth":1000,"backgroundColor":"transparent"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"isIconAfterHeader":false}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"backgroundColor":"transparent"}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-graphql-component/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Axiforma"],"urls":["../src/frontend/styles/axiforma-font/stylesheet.css"]},"google":{"families":["Source Code Pro","Source Code Pro:bold"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dev Simulator","short_name":"dev-simulator","start_url":"/","icon":"src/frontend/assets/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6fc45ea0c364763ec8456868ac8c93d9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/simulator.dev/simulator.dev/src/frontend/components/AppContextProvider/AppContextProvider.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":".gatsby","babel":{"overrides":[{"test":["**/*.ts"],"presets":[["@babel/preset-typescript",{"isTSX":false}]]},{"test":["**/*.tsx"],"presets":[["@babel/preset-typescript",{"isTSX":true,"allExtensions":true}]]}]}},
    }]
