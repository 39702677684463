import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import {
  randomBoolean,
  randomNumber,
  uid,
} from '../../../utils/helpers/helpers'
import BooleanUIField from '../../UIField/Boolean/BooleanUIField'
import InMemoryUIModelAPI from '../../UIFieldCollection/UIModel/API/InMemory/InMemoryUIModelAPI'
import UIModelAPI from '../../UIFieldCollection/UIModel/API/UIModelAPI'
import UIModel, {
  UIModelFields,
  UIModelOptions,
} from '../../UIFieldCollection/UIModel/UIModel'
import Game from '../Game'

interface Task {
  id: string
  name: string
  instructions: JSX.Element | string
  solution: JSX.Element | string
}

export default Task

export class PlayerTask extends UIModel implements Task {
  name: string
  instructions: JSX.Element | string
  solution: JSX.Element | string
  isComplete: BooleanUIField

  constructor(
    task: Task,
    gameId: string,
    isComplete: boolean,
    uiModelAPI: UIModelAPI,
    options?: UIModelOptions
  ) {
    const isCompleteField = new BooleanUIField(
      'isComplete',
      'checkbox',
      isComplete,
      {
        displayName: 'Complete',
      }
    )

    super(
      task.id,
      [isCompleteField],
      `${Game.collection}/${gameId}/tasks`,
      uiModelAPI,
      options
    )

    this.name = task.name
    this.instructions = task.instructions
    this.solution = task.solution
    this.isComplete = isCompleteField

    makeObservable(this, {
      name: observable,
      instructions: observable,
      solution: observable,
      isComplete: observable,
    })
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    return new PlayerTask(
      {
        id: uid(),
        name: lorem.generateWords(randomNumber(1, 3)),
        instructions: lorem.generateSentences(randomNumber(1, 5)),
        solution: lorem.generateSentences(randomNumber(1, 5)),
      },
      uid(),
      randomBoolean(),
      new InMemoryUIModelAPI()
    )
  }
}

export interface TaskSaveObject extends UIModelFields {
  isComplete: boolean
}
