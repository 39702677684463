import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { HtmlSpan } from '../../../../utils/types'
import Spinner from '../../Spinner/Spinner'
import { observer } from 'mobx-react-lite'
import useApp from '../../../AppContextProvider/hooks/useApp'

const Button = ({
  children,
  onClick = () => {},
  loading = false,
  disabled = false,
  className,
  ...rest
}: ButtonProps) => {
  const app = useApp()
  return (
    <div>
      <div className={`inline-block rounded-lg`} {...rest}>
        <button
          className={`select-none py-1 px-2 font-bold rounded-lg  ${
            disabled ? 'disabled' : loading ? 'loading' : 'active'
          } text-light bg-primary ${className}`}
          onClick={() => {
            if (!disabled && !loading) onClick()
          }}
        >
          {loading && (
            <Spinner size={'1.5rem'} color={app.theme.text.colors.light} />
          )}
          <div className={`${loading && 'invisible'}`}>{children}</div>
        </button>
      </div>
    </div>
  )
}

export interface ButtonProps extends HtmlSpan {
  onClick?: () => void
  loading?: boolean
  disabled?: boolean
}

export default styled(observer(Button))`
  height: 2rem;
  box-shadow: 0.15rem 0.15rem 0 ${(props) => props.theme.text.colors.base};

  :focus {
    ${tw`outline-none`}
  }

  &.active:active {
    ${tw`outline-none shadow-none`}
    transform: translate(0.15rem, 0.15rem);
  }

  &.disabled {
    background: ${(props) => props.theme.background.colors.accent};
    color: ${(props) => props.theme.text.colors.accent};
    ${tw`cursor-not-allowed`};
  }

  &.loading {
    ${tw`cursor-not-allowed`};
  }
`
