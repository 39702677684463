import { getStripe } from '../../../../utils/stripe/stripe'
import Firebase from '../../../Firebase/Firebase'
import FirebaseUIModelAPI from '../../../UIFieldCollection/UIModel/API/Firebase/FirebaseUIModelAPI'
import FirebaseUserAPI from '../../../User/API/Firebase/FirebaseUserAPI'
import App from '../../App'

class FirebaseEmulatorApp extends App {
  constructor() {
    const firebase = new Firebase(true)
    super(
      new FirebaseUIModelAPI(firebase),
      new FirebaseUserAPI(firebase, getStripe)
    )
  }
}

export default FirebaseEmulatorApp
