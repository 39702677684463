import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import GatsbyLink from 'gatsby-link'
import { observer } from 'mobx-react-lite'

const Link = ({
  children,
  to,
  activeClassName = '',
  className,
  ...rest
}: LinkProps) => {
  return (
    <div {...rest}>
      <GatsbyLink to={to} activeClassName={activeClassName}>
        <div className={`${className}`}>{children}</div>
      </GatsbyLink>
    </div>
  )
}

export interface LinkProps extends HtmlDiv {
  to: string
  activeClassName?: string
}

export default styled(observer(Link))``
