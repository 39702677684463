import StringUIField, { StringUIFieldOptions } from '../StringUIField'

class EmailUIField extends StringUIField {
  constructor(name: string, value: string, options?: StringUIFieldOptions) {
    super(name, 'email', value, {
      ...options,
      maxWords: 1,
      minWords: 1,
      match: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    })
  }
}

export default EmailUIField
