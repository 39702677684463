// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-frontend-pages-404-tsx": () => import("./../../../src/frontend/pages/404.tsx" /* webpackChunkName: "component---src-frontend-pages-404-tsx" */),
  "component---src-frontend-pages-blog-tsx": () => import("./../../../src/frontend/pages/blog.tsx" /* webpackChunkName: "component---src-frontend-pages-blog-tsx" */),
  "component---src-frontend-pages-developer-docs-tsx": () => import("./../../../src/frontend/pages/developer-docs.tsx" /* webpackChunkName: "component---src-frontend-pages-developer-docs-tsx" */),
  "component---src-frontend-pages-docs-tsx": () => import("./../../../src/frontend/pages/docs.tsx" /* webpackChunkName: "component---src-frontend-pages-docs-tsx" */),
  "component---src-frontend-pages-index-tsx": () => import("./../../../src/frontend/pages/index.tsx" /* webpackChunkName: "component---src-frontend-pages-index-tsx" */),
  "component---src-frontend-pages-open-source-tsx": () => import("./../../../src/frontend/pages/open-source.tsx" /* webpackChunkName: "component---src-frontend-pages-open-source-tsx" */),
  "component---src-frontend-templates-blog-tsx": () => import("./../../../src/frontend/templates/Blog.tsx" /* webpackChunkName: "component---src-frontend-templates-blog-tsx" */)
}

