import React, { useState } from 'react'
import styled from 'styled-components'
import { UserInputElement } from '../../../../utils/types'
import Icon from '../../Content/Icon/Icon'
import Spinner from '../../Spinner/Spinner'
import { observer } from 'mobx-react-lite'
import useApp from '../../../AppContextProvider/hooks/useApp'

const Input = ({
  value,
  onUpdate,
  onEnter = () => {},
  type = 'text',
  placeholder = '',
  disabled = false,
  clearable = false,
  loading = false,
  name = '',
  className,
  ...rest
}: InputProps) => {
  const app = useApp()

  const [statefulType, setStatefulType] = useState(type)
  return (
    <div
      className={`w-full rounded-lg px-2 box-border overflow-hidden flex items-center ${
        disabled
          ? 'cursor-not-allowed text-accent bg-accent select-none'
          : 'bg-bold'
      } ${className}`}
    >
      <input
        value={value}
        onChange={(e) => {
          if (!disabled) onUpdate(e.currentTarget.value)
        }}
        placeholder={placeholder}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !disabled) onEnter()
        }}
        name={name}
        type={statefulType}
        disabled={disabled}
        className={`text-base
        ${disabled ? 'bg-accent' : 'bg-bold'}
        ${
          disabled || (loading && 'cursor-not-allowed text-accent select-none')
        } outline-none flex-grow h-full py-1`}
        {...rest}
      />
      {clearable && type !== 'password' && !loading && value.length > 0 && (
        <Icon
          name="close"
          className="ml-2 cursor-pointer text-xl"
          onClick={() => onUpdate('')}
        />
      )}
      {type === 'password' && (
        <Icon
          name={statefulType === 'password' ? 'show' : 'hide'}
          className="ml-2 cursor-pointer text-2xl"
          onClick={() =>
            setStatefulType(statefulType === 'password' ? 'text' : 'password')
          }
        />
      )}
      {loading && <Spinner size={`${app.theme.text.lineHeight}px`} />}
    </div>
  )
}

export interface InputProps extends UserInputElement<string> {
  onEnter?: () => void
  placeholder?: string
  type?: string
  clearable?: boolean
}

export default styled(observer(Input))`
  border: 0.2rem solid ${(props) => props.theme.background.colors.primary.base};
  height: ${(props) => props.theme.text.lineHeight};
  input {
    min-width: 0;
  }
`
