import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../../utils/types'
import Spinner from '../../../Spinner/Spinner'

const Radio = ({
  children,
  description,
  onSelect = () => {},
  selected = false,
  disabled = false,
  loading = false,
  error = false,
  className,
  ...rest
}: RadioProps) => {
  return (
    <div
      className={`${
        disabled ? 'cursor-not-allowed' : 'cursor'
      } cursor-pointer flex flex-wrap ${className}`}
      onClick={() => {
        onSelect()
      }}
      {...rest}
    >
      <div className="radio-circle mr-3 p-2 box-border rounded-full flex items-center justify-center">
        {selected && !loading && (
          <div
            className={`check rounded-full ${
              disabled ? 'bg-accent' : 'bg-primary'
            } flex-shrink-0`}
          ></div>
        )}
        {loading && <Spinner size="0.75rem" />}
      </div>
      <div>
        <div className={`${disabled && 'text-accent cursor-not-allowed'}`}>
          {children}
        </div>
        {description && (
          <div className={`text-xs ${error ? 'text-error' : 'text-accent'}`}>
            {description}
          </div>
        )}
      </div>
    </div>
  )
}

export interface RadioProps extends HtmlDiv {
  description?: string
  onSelect?: () => void
  selected?: boolean
  disabled?: boolean
  loading?: boolean
  error?: boolean
}

export default styled(observer(Radio))`
  > .radio-circle {
    border: 0.2rem solid ${(props) => props.theme.text.colors.base};
    width: 0.75rem;
    height: 0.75rem;

    .check {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
`
