import { makeObservable, observable } from 'mobx'
import UIField, { UIFieldOptions } from '../UIField'

class BooleanUIField extends UIField<boolean> {
  forceTrue: boolean

  constructor(
    name: string,
    type: 'checkbox' | 'switch',
    value: boolean,
    options?: BooleanUIFieldOptions
  ) {
    super(name, type, value, {
      validateOnValueChange: true,
      ...options,
      validate: async () => {
        const errors = []

        if (options?.validate) errors.push(...(await options.validate()))

        if (this.forceTrue && !this.value)
          errors.push('This field must be checked')

        return errors
      },
    })

    this.forceTrue = options?.forceTrue || false

    makeObservable(this, {
      forceTrue: observable,
    })
  }
}

export default BooleanUIField

export interface BooleanUIFieldOptions extends UIFieldOptions<boolean> {
  forceTrue?: boolean
}
