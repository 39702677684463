import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../../../utils/types'

const FormFieldControl = ({
  children,
  label = '',
  caption = '',
  error = false,
  positive = false,
  className,
  ...rest
}: FormFieldControlProps) => {
  return (
    <div className={`${className}`} {...rest}>
      {label && <div className={`text-sm mb-1 font-bold`}>{label}</div>}
      {children}

      <div
        className={`form-field-caption text-xs  ${positive && 'text-success'} ${
          error && 'text-error'
        }  mt-1`}
      >
        {caption || <span>&nbsp;</span>}
      </div>
    </div>
  )
}

export interface FormFieldControlProps extends HtmlDiv {
  label?: string
  caption?: string
  error?: boolean
  positive?: boolean
}

export default styled(observer(FormFieldControl))``
