import { v4 as uidv4 } from 'uuid'
import * as EmailValidator from 'email-validator'

export const runningOnBrowser = () => {
  return typeof window !== 'undefined'
}

export const uid = () => {
  return `${Date.now()}-${uidv4()}`
}

export const randomNumber = (min: number, max: number) => {
  const minCeil = Math.ceil(min)
  const maxCeil = Math.floor(max)
  return Math.floor(Math.random() * (maxCeil - minCeil + 1)) + minCeil
}

export const randomBoolean = () => {
  return Math.random() < 0.5
}

export const isValidEmail = (email: string) => {
  return EmailValidator.validate(email)
}

export const sleep = async (sleepDuration: number) => {
  return new Promise((res) => {
    setTimeout(() => {
      res('')
    }, sleepDuration)
  })
}

export const getThrownErrorAsync = async (func: () => Promise<unknown>) => {
  try {
    await func()
    return null
  } catch (e) {
    return e
  }
}
