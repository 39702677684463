import { makeObservable, observable } from 'mobx'

class UIError {
  readonly code: string
  readonly message: string
  readonly rawUIError?: unknown
  readonly isInternalUIError = true

  constructor(code: string, message: string, rawUIError?: unknown) {
    this.code = code
    this.message = message
    this.rawUIError = rawUIError

    makeObservable(this, {
      code: observable,
      message: observable,
      rawUIError: observable,
    })
  }
}

export default UIError
