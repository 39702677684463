import { makeObservable, observable } from 'mobx'
import {
  randomBoolean,
  randomNumber,
  uid,
} from '../../../../utils/helpers/helpers'
import BooleanUIField from '../../../UIField/Boolean/BooleanUIField'
import InMemoryUIModelAPI from '../../../UIFieldCollection/UIModel/API/InMemory/InMemoryUIModelAPI'
import UIModelAPI from '../../../UIFieldCollection/UIModel/API/UIModelAPI'
import UIModel, {
  UIModelFields,
  UIModelOptions,
} from '../../../UIFieldCollection/UIModel/UIModel'
import Contract, { PlayerContract } from '../../Contract/Contract'
import Game from '../../Game'
import Email from '../Email'

interface EmailThread {
  id: string
  emails: Email[]
  contract: Contract | null
}

export class PlayerEmailThread extends UIModel implements EmailThread {
  emails: Email[]
  contract: Contract | null
  isRead: BooleanUIField

  constructor(
    emailThread: EmailThread,
    gameId: string,
    isRead: boolean,
    uiModelAPI: UIModelAPI,
    options?: UIModelOptions
  ) {
    const isReadField = new BooleanUIField('isRead', 'checkbox', isRead, {
      displayName: 'Read',
    })
    super(
      emailThread.id,
      [isReadField],
      `${gameId}/emailThreads`,
      uiModelAPI,
      options
    )

    this.emails = emailThread.emails
    this.contract = emailThread.contract
    this.isRead = isReadField

    makeObservable(this, {
      emails: observable,
      contract: observable,
      isRead: observable,
    })
  }

  static async loadAll(gameId: string, uiModelAPI: UIModelAPI) {
    const savedEmailThreads = (await uiModelAPI.getAll(
      `${Game.collection}/${gameId}/emailThreads`
    )) as EmailThreadSaveObject[]
    const playerEmailThreads = emailThreads.map((emailThread) => {
      const savedEmailThread = savedEmailThreads.find(
        (e) => e.id === emailThread.id
      )
      return new PlayerEmailThread(
        emailThread,
        gameId,
        !!savedEmailThread?.isRead,
        uiModelAPI,
        { isSaved: true }
      )
    })
    return playerEmailThreads
  }

  static withFakeData() {
    const emails: Email[] = []
    for (let i = 0; i < randomNumber(0, 5); i++)
      emails.push(Email.withFakeData())

    return new PlayerEmailThread(
      {
        id: uid(),
        emails,
        contract: PlayerContract.withFakeData(),
      },
      uid(),
      randomBoolean(),
      new InMemoryUIModelAPI()
    )
  }
}

export interface EmailThreadSaveObject extends UIModelFields {
  isRead: boolean
}

const emailThreads: EmailThread[] = []
