import React from 'react'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
// @ts-ignore
import { agate } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import { observer } from 'mobx-react-lite'
import useApp from '../../../AppContextProvider/hooks/useApp'

const CodeBlock = ({
  children,
  language,
  highlightedLines = [],
  showLineNumbers = false,
  filename,
  className,
  ...rest
}: CodeBlockProps) => {
  const app = useApp()
  return (
    <div className={`${className}`} {...rest}>
      {filename && (
        <span className="filename whitespace-nowrap inline-block z-10 px-2 max-w-xs overflow-scroll relative text-light rounded">
          <div>{filename}</div>
        </span>
      )}
      <div className="rounded-lg code-container overflow-hidden bg-dark relative">
        <SyntaxHighlighter
          className="code-block bg-code"
          language={language}
          showLineNumbers={showLineNumbers}
          wrapLines
          lineProps={(lineNumber: number) => {
            if (highlightedLines.includes(lineNumber))
              return {
                style: {
                  background: app.theme.background.colors.primary.bold,
                },
              }
            return {}
          }}
          style={agate}
        >
          {children}
        </SyntaxHighlighter>
      </div>
    </div>
  )
}

export interface CodeBlockProps extends HtmlDiv {
  language: string
  highlightedLines: number[]
  filename?: string
  showLineNumbers?: boolean
}

export default styled(observer(CodeBlock))`
  .code-container {
    border: 0.2rem solid
      ${(props) => props.theme.background.colors.primary.base};
  }

  .filename {
    border: 0.2rem solid
      ${(props) => props.theme.background.colors.primary.base};
    border-bottom: none;
    left: 0.5rem;
    background: rgb(51, 51, 51);
    top: 4px;
  }
`
