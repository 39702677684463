import { observer } from 'mobx-react-lite'
import React, { createContext, useEffect, useState } from 'react'
import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'
import App from '../../models/App/App'
import FirebaseEmulatorApp from '../../models/App/Firebase/Emulator/FirebaseEmulatorApp'
import FirebaseApp from '../../models/App/Firebase/FirebaseApp'
import { HtmlDiv } from '../../utils/types'

const initialApp =
  App.getEnvironment() === 'development'
    ? new FirebaseEmulatorApp()
    : new FirebaseApp()

export const AppContext = createContext({})
const AppContextProvider = ({ children }: HtmlDiv) => {
  const [app] = useState(initialApp)
  const GlobalStyle = createGlobalStyle`${app.theme.toCSS()}`

  useEffect(() => {
    app.initializeUser()
  }, [])

  return (
    <AppContext.Provider value={{ app } as AppContext}>
      <StyledComponentsThemeProvider theme={app.theme}>
        <GlobalStyle />
        {children}
      </StyledComponentsThemeProvider>
    </AppContext.Provider>
  )
}

export const { Consumer: AppContextConsumer } = AppContext

export interface AppContext {
  app: App
}

export function withAppContextProvider<P>(
  WrappedComponent: React.ComponentType<P>
) {
  return (props: P) => {
    return (
      <AppContextProvider>
        <WrappedComponent {...props} />
      </AppContextProvider>
    )
  }
}

export default observer(AppContextProvider)
