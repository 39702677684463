import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber } from '../../../utils/helpers/helpers'
class JobTitle {
  name: string
  prerequisiteContractIds: string[]

  constructor(name: string, prerequisiteContractIds: string[]) {
    this.name = name
    this.prerequisiteContractIds = prerequisiteContractIds

    makeObservable(this, {
      name: observable,
      prerequisiteContractIds: observable,
    })
  }

  isUnlocked(playerCompletedContractIds: string[]) {
    let hasQualifyingCompletedContracts = true
    this.prerequisiteContractIds.forEach((prerequisiteContractId) => {
      if (!playerCompletedContractIds.includes(prerequisiteContractId))
        hasQualifyingCompletedContracts = false
    })

    return hasQualifyingCompletedContracts
  }

  static withFakeData() {
    const lorem = new LoremIpsum()

    return new JobTitle(lorem.generateWords(randomNumber(1, 3)), [])
  }
}

export const jobTitles: JobTitle[] = []

export default JobTitle
