import React from 'react'
import styled from 'styled-components'
import { UserInputElement } from '../../../../utils/types'
import { Range } from 'react-range'
import { observer } from 'mobx-react-lite'
import useApp from '../../../AppContextProvider/hooks/useApp'

const Slider = ({
  value,
  max,
  min,
  onUpdate,
  step = 1,
  disabled = false,
  trackColor,
  className,
  ...rest
}: SliderProps) => {
  const app = useApp()
  if (!trackColor) trackColor = app.theme.background.colors.primary.base

  const inRangeValue = value < min ? min : value > max ? max : value

  return (
    <div
      className={`${disabled && 'cursor-not-allowed'} my-6 ${className}`}
      {...rest}
    >
      <Range
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        values={[inRangeValue]}
        onChange={(values: number[]) => {
          if (!disabled) onUpdate(values[0])
        }}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className={`slider-track w-full rounded-lg`}
            style={{
              ...props.style,
              background: disabled
                ? app.theme.background.colors.accent
                : trackColor,
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className="slider-thumb outline-none relative"
            style={props.style}
          >
            <div className="slider-thumb-handle rounded-full outline-none" />
            <div className="slider-thumb-value absolute mt-2">{value}</div>
          </div>
        )}
      />

      <div
        className={`flex justify-between items-center ${
          disabled && 'text-accent'
        }`}
      >
        <div>{value > min + max * 0.05 && min}</div>
        <div>{value < max - max * 0.05 && max}</div>
      </div>
    </div>
  )
}

export interface SliderProps extends UserInputElement<number> {
  max: number
  min: number
  step?: number
  trackColor?: string
}

export default styled(observer(Slider))`
  .slider-thumb {
    .slider-thumb-handle {
      height: 1.5rem;
      width: 1.5rem;
      background: ${(props) =>
        props.disabled
          ? props.theme.background.colors.accent
          : props.theme.text.colors.base};
    }
    .slider-thumb-value {
      left: ${(props) => (props.value < 10 ? '0.4rem' : '0.1rem')};
      color: ${(props) =>
        props.disabled
          ? props.theme.text.colors.accent
          : props.theme.text.colors.base};
    }
  }
  .slider-track {
    height: 0.5rem;
  }
`
