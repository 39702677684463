import { makeObservable, observable } from 'mobx'
import UIField from '../../UIField/UIField'
import UIFieldCollection from '../UIFieldCollection'

class UIForm extends UIFieldCollection {
  isSubmitting = false
  errors: string[] = []
  actionText: string
  private _submit: () => void | Promise<void>

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: UIField<any>[],
    submit: () => void | Promise<void>,
    actionText = 'submit'
  ) {
    super(fields)
    this._submit = submit
    this.actionText = actionText
    makeObservable(this, {
      isSubmitting: observable,
      errors: observable,
      actionText: observable,
    })
  }

  async submit() {
    try {
      this.errors = []
      this.isSubmitting = true
      await this.validate()
      if (this.isValid) {
        await this._submit()
      }
    } catch (e) {
      this.errors.push(e.message)
    } finally {
      this.isSubmitting = false
    }
  }
}

export default UIForm
