import { sleep } from '../../../../../utils/helpers/helpers'
import UIModelAPI, {
  UIModelFilters,
  UIModelFilter_ComparisonOperator,
} from '../UIModelAPI'

class InMemoryUIModelAPI implements UIModelAPI {
  _appModels: Record<string, Record<string, Record<string, unknown>>> = {}
  responseDelay: number

  constructor(responseDelay = 0) {
    this.responseDelay = responseDelay
  }

  async get(
    id: string,
    collection: string
  ): Promise<Record<string, unknown> | null> {
    await sleep(this.responseDelay)
    if (this._appModels[collection] && this._appModels[collection][id])
      return this._appModels[collection][id]
    return null
  }
  async getAll(
    collection: string,
    filters?: UIModelFilters
  ): Promise<Record<string, unknown>[]> {
    await sleep(this.responseDelay)
    if (this._appModels[collection]) {
      let results = Object.keys(this._appModels[collection]).map((id) => {
        return { ...this._appModels[collection][id], id }
      }) as Record<string, unknown>[]
      if (filters) {
        filters.forEach((filter) => {
          results = results.filter((result) => {
            const comparisonOperator: UIModelFilter_ComparisonOperator =
              filter[1]
            const fieldName = filter[0]

            const fieldValue = result[fieldName]
            const comparisonValue = filter[2]

            switch (comparisonOperator) {
              case '==':
                return fieldValue === comparisonValue
              case '!=':
                return fieldValue !== comparisonValue
              case '<':
                return (
                  (fieldValue as number | string) <
                  (comparisonValue as number | string)
                )
              case '<=':
                return (
                  (fieldValue as number | string) <=
                  (comparisonValue as number | string)
                )
              case '>':
                return (
                  (fieldValue as number | string) >
                  (comparisonValue as number | string)
                )
              case '>=':
                return (
                  (fieldValue as number | string) >=
                  (comparisonValue as number | string)
                )
              case 'in':
                return (comparisonValue as unknown[]).includes(fieldValue)
              default:
                return true
            }
          })
        })
      }
      return results
    }

    return []
  }
  async set(
    id: string,
    data: Record<string, unknown>,
    collection: string
  ): Promise<void> {
    await sleep(this.responseDelay)
    if (!this._appModels[collection]) this._appModels[collection] = {}
    this._appModels[collection][id] = data
  }
  async remove(id: string, collection: string): Promise<void> {
    await sleep(this.responseDelay)
    if (this._appModels[collection]) delete this._appModels[collection][id]
  }
}

export default InMemoryUIModelAPI
