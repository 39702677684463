import { LoremIpsum } from 'lorem-ipsum'
import { makeObservable, observable } from 'mobx'
import { randomNumber } from '../../../../frontend/utils/helpers/helpers'

class Skill {
  name: string
  xp: number
  description: JSX.Element | string
  image: string

  constructor(
    name: string,
    xp: number,
    description: JSX.Element | string,
    image: string
  ) {
    this.name = name
    this.xp = xp
    this.description = description
    this.image = image

    makeObservable(this, {
      name: observable,
      xp: observable,
      description: observable,
      image: observable,
    })
  }

  static withFakeData = () => {
    const lorem = new LoremIpsum()

    return new Skill(
      lorem.generateWords(randomNumber(1, 2)),
      randomNumber(1, 9999),
      lorem.generateSentences(randomNumber(1, 2)),
      'https://drive.google.com/uc?export=download&id=16AE4N6rfcxJIR-P6jTy2cLhhfhBKfdWC'
    )
  }
}

export const skills: Skill[] = []

export default Skill
